// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleChampionsQueueBySlug } from 'services/championsQueues';
import { fetchChampionsQueueOverivew } from 'services/championsQueueData';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import OverviewData from 'components/templates/OverviewData/OverviewData';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const ChampionsQueueSingleView = () => {
  const {
    setTitleSEO,
    filters: {
      championsQueue: { id, rangeDate, side },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { regionSlug } = useParams();
  const [championsQueue, setChampionsQueue] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (regionValue) => {
    setIsLoading(true);

    fetchSingleChampionsQueueBySlug(regionValue).then((res) => setChampionsQueue(res));
  };

  const fetchData = (sideData, regionId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchChampionsQueueOverivew(sideData, regionId, startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(regionSlug);
    }

    renderAfterCalled.current = true;
  }, [regionSlug]);

  useEffect(() => {
    if (championsQueue) {
      fetchData(side, championsQueue.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, side, championsQueue]);

  useEffect(() => {
    if (championsQueue) {
      const tempTitle = `Champions Queue ${championsQueue.name} Overview`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== championsQueue.id) {
        setFilters((prevValue) => ({
          ...prevValue,
          championsQueue: {
            ...prevValue.championsQueue,
            id: championsQueue.id,
          },
        }));
      }
    }
  }, [championsQueue]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter sideFilter type="championsQueue" />
          <OverviewData data={data} side={side} championsQueueData />
        </>
      }
    />
  );
};

export default ChampionsQueueSingleView;
