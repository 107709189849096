// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchWardsSurvived } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid2 as Grid, Typography } from '@mui/material';
import Heatmap from 'components/atoms/Heatmap/Heatmap';
import AreaMap from 'components/atoms/AreaMap/AreaMap';

const TeamSingleWardsSurvivedView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        wardType,
        timerange,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [survived, setSurvived] = useState(0);
  const [count, setCount] = useState(0);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    wardTypeData,
    timerangeData,
    teamId,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchWardsSurvived(
      itemType,
      sideData,
      wardTypeData,
      timerangeData,
      teamId,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      setData(res.data.data);
      setAreaData(res.data.areas);
      setSurvived(res.data.survived);
      setCount(res.count);

      setFilters((prevValue) => ({
        ...prevValue,
        team: {
          ...prevValue.team,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        wardType,
        timerange,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, wardType, timerange, team, patches, opponents, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Wards Survived`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            wardTypeFilter
            timerangeFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="team"
          />
          <Grid container>
            <Grid size={{ xs: 12, md: 6, lg: 5, xl: 4 }}>
              <Heatmap data={data} count={count} />
            </Grid>
            <Grid size={{ xs: 12, md: 6, lg: 5, xl: 4 }}>
              <AreaMap data={areaData} />
            </Grid>
            <Grid size={{ xs: 12, md: 6, lg: 2, xl: 4 }}>
              <Typography variant="body1" component="p">
                Survived Wards: {survived}%
              </Typography>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleWardsSurvivedView;
