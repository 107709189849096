// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchOverivew } from 'services/data';

// Constants
import { MATCH_TYPES } from 'constants/match';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import OverviewData from 'components/templates/OverviewData/OverviewData';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const ScrimsView = () => {
  const {
    setTitleSEO,
    filters: {
      scrims: { rangeDate, side, result, patches, patches_data, opponents, opponents_data, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const title = 'Scrims Overview';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const fetchData = (
    itemType,
    sideData,
    resultData,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchOverivew(
      itemType,
      sideData,
      resultData,
      null,
      null,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        scrims: {
          ...prevValue.scrims,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(
        MATCH_TYPES.SCRIM,
        side,
        result,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
      );
    }
  }, [rangeDate, side, result, patches, opponents, blitz]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters
            rangeDateFilter
            sideFilter
            resultFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="scrims"
          />
          <OverviewData data={data} teamData />
        </>
      }
    />
  );
};

export default ScrimsView;
