// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Elements
import { Checkbox, TextField, Autocomplete, Chip, createFilterOptions } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Component
const MultiAutocomplete = ({
  name = null,
  label,
  optionLabel = 'name',
  options,
  value = null,
  compareValue = 'id',
  disabled = false,
  onChangeFunction = null,
  onChangeField = null,
  onChange = null,
  id = null,
  type = null,
  error = false,
  helperText = '',
  isOptionEqualToValue = null,
  freeSolo = false,
  newItemType = 'Unknown',
  minWidth = null,
}) => {
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(value);
  }, [value]);

  const handleAutocompleteChange = (_, newValue, reason) => {
    if (onChange) {
      onChange((prevValue) => ({
        ...prevValue,
        [type]: { ...prevValue[type], [id]: newValue },
      }));
      setNewData(newValue);
    } else if (freeSolo) {
      if (reason === 'createOption') {
        const lastItem = newValue[newValue.length - 1];
        const newDataItem = [
          ...newData,
          { id: generateUniqueKey(), name: lastItem, type: newItemType },
        ];

        setNewData(newDataItem);
        onChangeFunction(onChangeField, newDataItem);
      } else {
        setNewData(newValue);
        onChangeFunction(onChangeField, newValue);
      }
    } else {
      setNewData(newValue);
      onChangeFunction(onChangeField, newValue);
    }
  };

  const renderTags = (el, getTagProps) =>
    el.map((option, index) => (
      <Chip {...getTagProps({ index })} key={option.id} label={option[optionLabel]} />
    ));

  const renderOption = (props, option, { selected }) => (
    <li {...props} key={option[optionLabel]}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option[optionLabel]}
    </li>
  );

  const getOptionLabel = (option) => option[optionLabel];

  const renderInput = (params) => (
    <TextField {...params} label={label} error={error} helperText={helperText} />
  );

  const filter = createFilterOptions();

  const filterOptions = (myOptions, params) => {
    const filtered = filter(myOptions, params);
    const { inputValue } = params;
    const isExisting = myOptions.some((option) => inputValue === option.title);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id: generateUniqueKey(),
        name: inputValue,
        type: newItemType,
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      style={{ minWidth }}
      multiple
      filterOptions={freeSolo ? filterOptions : filter}
      filterSelectedOptions
      disableCloseOnSelect
      disabled={disabled}
      name={name}
      options={options}
      value={newData}
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={
        isOptionEqualToValue == null
          ? (option, valueData) => option[compareValue] === valueData[compareValue]
          : isOptionEqualToValue
      }
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
      freeSolo={freeSolo}
    />
  );
};

MultiAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeFunction: PropTypes.func,
  onChangeField: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object),
  compareValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
  freeSolo: PropTypes.bool,
  newItemType: PropTypes.string,
  minWidth: PropTypes.number,
};

export default MultiAutocomplete;
