// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import CardWinRate from 'components/molecules/CardWinRate/CardWinRate';

// Component
const WinRateData = ({ data = {}, title }) => {
  const gamesData = [
    {
      title: 'All',
      count: data?.combined?.all?.count,
      winRate: data?.combined?.all?.win_rate,
      mt: 2,
    },
    {
      title: 'None',
      count: data?.combined?.none?.count,
      winRate: data?.combined?.none?.win_rate,
      mt: 0.25,
    },
    {
      title: 'FD + FH',
      count: data?.combined?.dragon_herald?.count,
      winRate: data?.combined?.dragon_herald?.win_rate,
      mt: 2,
    },
    {
      title: 'FD + FT',
      count: data?.combined?.dragon_tower?.count,
      winRate: data?.combined?.dragon_tower?.win_rate,
      mt: 0.25,
    },
    {
      title: 'FH + FT',
      count: data?.combined?.herald_tower?.count,
      winRate: data?.combined?.herald_tower?.win_rate,
      mt: 0.25,
    },
  ];

  const killData = [
    {
      title: 'First',
      count: data?.blood?.first?.count,
      winRate: data?.blood?.first?.win_rate,
    },
    {
      title: '+ FD',
      count: data?.combined?.blood_dragon?.count,
      winRate: data?.combined?.blood_dragon?.win_rate,
      mt: 2,
    },
    {
      title: '+ FH',
      count: data?.combined?.blood_herald?.count,
      winRate: data?.combined?.blood_herald?.win_rate,
      mt: 0.25,
    },
    {
      title: '+ FT',
      count: data?.combined?.blood_tower?.count,
      winRate: data?.combined?.blood_tower?.win_rate,
      mt: 0.25,
    },
  ];

  const dragonsData = [
    {
      title: 'First',
      count: data?.dragons?.first?.count,
      winRate: data?.dragons?.first?.win_rate,
    },
    {
      title: 'First @6',
      count: data?.dragons?.first_6?.count,
      winRate: data?.dragons?.first_6?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First @8',
      count: data?.dragons?.first_8?.count,
      winRate: data?.dragons?.first_8?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First @10',
      count: data?.dragons?.first_10?.count,
      winRate: data?.dragons?.first_10?.win_rate,
      mt: 0.25,
    },
    {
      title: 'Stack 2',
      count: data?.dragons?.stack_2?.count,
      winRate: data?.dragons?.stack_2?.win_rate,
      mt: 2,
    },
    {
      title: 'Stack 3',
      count: data?.dragons?.stack_3?.count,
      winRate: data?.dragons?.stack_3?.win_rate,
      mt: 0.25,
    },
    {
      title: 'Soul',
      count: data?.dragons?.soul?.count,
      winRate: data?.dragons?.soul?.win_rate,
      mt: 0.25,
    },
  ];

  const objectivesData = [
    {
      title: 'Min. 2 grubs',
      count: data?.objectives?.min_2_grubs?.count,
      winRate: data?.objectives?.min_2_grubs?.win_rate,
    },
    {
      title: 'Min. 5 grubs',
      count: data?.objectives?.min_5_grubs?.count,
      winRate: data?.objectives?.min_5_grubs?.win_rate,
      mt: 0.25,
    },
    {
      title: '6 grubs',
      count: data?.objectives?.all_grubs?.count,
      winRate: data?.objectives?.all_grubs?.win_rate,
      mt: 0.25,
    },
    {
      title: 'Herald',
      count: data?.objectives?.herald?.count,
      winRate: data?.objectives?.herald?.win_rate,
      mt: 2,
    },
    {
      title: 'Atakhan',
      count: data?.objectives?.atakhan?.count,
      winRate: data?.objectives?.atakhan?.win_rate,
      mt: 0.25,
    },
    {
      title: '1st Baron',
      count: data?.objectives?.baron?.count,
      winRate: data?.objectives?.baron?.win_rate,
      mt: 0.25,
    },
  ];

  const towersData = [
    {
      title: 'First',
      count: data?.towers?.first?.count,
      winRate: data?.towers?.first?.win_rate,
    },
    {
      title: 'First @12',
      count: data?.towers?.first_12?.count,
      winRate: data?.towers?.first_12?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First @14',
      count: data?.towers?.first_14?.count,
      winRate: data?.towers?.first_14?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First @16',
      count: data?.towers?.first_16?.count,
      winRate: data?.towers?.first_16?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First TOP',
      count: data?.towers?.first_top?.count,
      winRate: data?.towers?.first_top?.win_rate,
      mt: 2,
    },
    {
      title: 'First MID',
      count: data?.towers?.first_mid?.count,
      winRate: data?.towers?.first_mid?.win_rate,
      mt: 0.25,
    },
    {
      title: 'First BOT',
      count: data?.towers?.first_bot?.count,
      winRate: data?.towers?.first_bot?.win_rate,
      mt: 0.25,
    },
  ];

  return (
    <>
      <PageTitle title={title} variant="h6" component="p" />
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(5, 1fr)',
        }}
        gap={1}
        mb={2}
      >
        <CardWinRate title="Games" count={data?.all} data={gamesData} />
        <CardWinRate title="Kill" data={killData} />
        <CardWinRate title="Dragons" data={dragonsData} />
        <CardWinRate title="Objectives" data={objectivesData} />
        <CardWinRate title="Towers" data={towersData} />
      </Box>
    </>
  );
};

WinRateData.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default WinRateData;
