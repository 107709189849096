// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchGraphs } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import ChartArea from 'components/organisms/ChartArea/ChartArea';
import ChartScatter from 'components/atoms/ChartScatter/ChartScatter';

const TeamSingleGraphsView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        graphTeamType,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    graphTeamTypeData,
    itemType,
    sideData,
    teamId,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchGraphs(
      graphTeamTypeData,
      itemType,
      sideData,
      teamId,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: {
          ...prevValue.team,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        graphTeamType,
        matchType,
        side,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
      );
    }
  }, [graphTeamType, matchType, rangeDate, side, team, patches, opponents, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Graphs`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            graphTeamTypeFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="team"
          />
          {!graphTeamType.includes('WIN_RATE') ? (
            <ChartArea
              data={data}
              chartKey="value"
              type={graphTeamType === 'JUNGLE_CAMPS' ? 'half' : null}
              yAxis={graphTeamType === 'JUNGLE_CAMPS' ? [0, 4] : null}
            />
          ) : (
            <ChartScatter data={data} />
          )}
        </>
      }
    />
  );
};

export default TeamSingleGraphsView;
