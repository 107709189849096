// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchChampions } from 'services/champions';
import { searchCounters } from 'services/leaguepedia';

// Constants
import { SEARCH_TEAM_C1_TYPE } from 'constants/team';
import { SEARCH_COUNTER_MATCH_TYPE_LIST } from 'constants/search';
import { HIDE_TOP_TOOLBAR } from 'constants/mrt';
import { ROLES } from 'constants/role';
import { SEARCH_COUNTER_COLS } from 'constants/tableHead';
import { LOADING_TEXT } from 'constants/text';

// Schemas
import {
  STRING_SCHEMA,
  BOOLEAN_SCHEMA,
  STRING_REQUIRED_SCHEMA,
  OBJECT_NULLABLE_SCHEMA,
} from 'schemas/base';
import { SEARCH_COUNTER_CHAMPIONS_SCHEMA, SEARCH_COUNTER_TEAM_ROLES_SCHEMA } from 'schemas/search';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid2 as Grid, FormControlLabel, Checkbox } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Select from 'components/atoms/Select/Select';
import MultiAutocomplete from 'components/atoms/MultiAutocomplete/MultiAutocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePikcer from 'components/atoms/DatePicker/DatePicker';

const SearchCounterView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [champions, setChampions] = useState([]);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Search Counter';

  const fetchData = () => {
    setIsLoading(true);

    fetchChampions().then((res) => {
      setChampions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    match_type: 'COMPETITIVE',
    start_date: null,
    end_date: null,
    patch: '',
    top: true,
    champions: [],
    champions_team: 'ANY',
    team_data: [],
  };

  const validationSchema = Yup.object().shape({
    match_type: STRING_REQUIRED_SCHEMA,
    start_date: OBJECT_NULLABLE_SCHEMA,
    end_date: OBJECT_NULLABLE_SCHEMA,
    patch: STRING_SCHEMA,
    top: BOOLEAN_SCHEMA,
    champions: SEARCH_COUNTER_CHAMPIONS_SCHEMA,
    champions_team: STRING_REQUIRED_SCHEMA,
    team_data: SEARCH_COUNTER_TEAM_ROLES_SCHEMA,
  });

  const columns = useMemo(() => SEARCH_COUNTER_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 12, lg: 5, xl: 4 }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setData([]);

                searchCounters(values).then(({ data: respData }) => {
                  setData(respData);

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container>
                      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                        <Select
                          disabled={SEARCH_COUNTER_MATCH_TYPE_LIST.length === 0}
                          error={touched.match_type && !!errors.match_type}
                          helperText={touched.match_type && errors.match_type}
                          name="match_type"
                          label="Games type"
                          optionLabel="label"
                          options={SEARCH_COUNTER_MATCH_TYPE_LIST}
                          displayEmpty={false}
                          value={values.match_type}
                          compareValue="value"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                        <DatePikcer
                          name="start_date"
                          label="Start Date"
                          value={values.start_date}
                          onChangeFn={setFieldValue}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                        <DatePikcer
                          name="end_date"
                          label="End Date"
                          value={values.end_date}
                          onChangeFn={setFieldValue}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                        <TextField
                          error={touched.patch && !!errors.patch}
                          helperText={touched.patch && errors.patch}
                          name="patch"
                          label="Patch"
                          value={values.patch}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                  <Grid container>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <MultiAutocomplete
                        disabled={champions.length === 0}
                        error={touched.champions && !!errors.champions}
                        helperText={touched.champions && errors.champions}
                        name="champions"
                        label="Champions"
                        options={champions}
                        value={values.champions}
                        onChangeFunction={setFieldValue}
                        onChangeField="champions"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Select
                        disabled={SEARCH_TEAM_C1_TYPE.length === 0}
                        error={touched.champions_team && !!errors.champions_team}
                        helperText={touched.champions_team && errors.champions_team}
                        name="champions_team"
                        label="Team"
                        options={SEARCH_TEAM_C1_TYPE}
                        displayEmpty={false}
                        value={values.champions_team}
                        compareValue="value"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid size={{ xs: 12 }}>
                      <MultiAutocomplete
                        disabled={ROLES.length === 0}
                        error={touched.team_data && !!errors.team_data}
                        helperText={touched.team_data && errors.team_data}
                        name="team_data"
                        label="Counter roles"
                        options={ROLES}
                        optionLabel="label"
                        value={values.team_data}
                        onChangeFunction={setFieldValue}
                        onChangeField="team_data"
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    disabled={values.match_type !== 'COMPETITIVE'}
                    control={<Checkbox id="top" name="top" />}
                    label="TOP leagues"
                    checked={values.top}
                    onChange={handleChange}
                  />
                  <Box mt={1}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      loadingIndicator={LOADING_TEXT}
                    >
                      Search
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
          <Grid size={{ xs: 12, lg: 6, xl: 5 }}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchCounterView;
