// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Dayjs
import dayjs from 'dayjs';

// Services
import { fetchSingleSplitBySlugs } from 'services/splits';
import { fetchOverivew } from 'services/data';

// Constants
import { MATCH_TYPES } from 'constants/match';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import OverviewData from 'components/templates/OverviewData/OverviewData';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const SplitSingleView = () => {
  const {
    setTitleSEO,
    filters: {
      split: { id, rangeDate, side, patches, patches_data },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => setSplit(res));
  };

  const fetchData = (itemType, sideData, splitId, startDateData, endDateData, patchesData) => {
    setIsLoading(true);

    fetchOverivew(
      itemType,
      sideData,
      null,
      splitId,
      null,
      startDateData,
      endDateData,
      patchesData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        split: {
          ...prevValue.split,
          patches_data: res.patches_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      fetchData(MATCH_TYPES.COMPETITIVE, side, split.id, rangeDate[0], rangeDate[1], patches);
    }
  }, [rangeDate, side, split, patches]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} Overview`;
      const startDate = split?.start_date ? dayjs(split?.start_date) : null;
      const endDate = split?.end_date ? dayjs(split?.end_date) : null;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== split.id || (rangeDate[0] === null && rangeDate[1] === null)) {
        setFilters((prevValue) => ({
          ...prevValue,
          split: {
            ...prevValue.split,
            id: split.id,
            rangeDate: [startDate, endDate],
          },
        }));
      }
    }
  }, [split]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter sideFilter patchesFilter={patches_data} type="split" />
          <OverviewData data={data} side={side} splitData />
        </>
      }
    />
  );
};

export default SplitSingleView;
