// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchMatches = async (
  itemType,
  side,
  splitId,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  isAdmin,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}matches/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (isAdmin !== undefined && isAdmin !== null) {
    url.searchParams.append('is_admin', isAdmin);
  }

  try {
    const res = await axiosLoadingInstance.get(url.toString());

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSingleMatch = (id) =>
  axiosLoadingInstance
    .get(`matches/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateMatch = (id, data) =>
  axiosInstance
    .put(`matches/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Match has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteMatch = (id) =>
  axiosInstance
    .delete(`matches/${id}/`)
    .then((res) => ({ data: res.data, message: 'Match has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
