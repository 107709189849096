// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchLibrary = async (
  user,
  matchType,
  side,
  teams,
  champions,
  tags,
  startDate,
  endDate,
  timerange,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}library/`);

  if (user !== undefined && user !== null) {
    url.searchParams.append('user', user);
  }

  if (matchType !== undefined && matchType !== null) {
    url.searchParams.append('item_type', matchType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teams !== undefined && teams !== null) {
    url.searchParams.append('teams', teams.map((team) => team.value).join(','));
  }

  if (champions !== undefined && champions !== null) {
    url.searchParams.append('champions', champions.map((champion) => champion.value).join(','));
  }

  if (tags !== undefined && tags !== null) {
    url.searchParams.append('tags', tags.map((tag) => tag.value).join(','));
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchGamesForLibrary = (data) =>
  axiosLoadingInstance
    .post('library/games/', data)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleLibrary = (id) =>
  axiosLoadingInstance
    .get(`library/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addLibrary = (data) =>
  axiosInstance
    .post(`library/add/`, data)
    .then((res) => ({ data: res.data, message: 'Link has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateLibrary = (id, data) =>
  axiosInstance
    .put(`library/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Link has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteLibrary = (id) =>
  axiosInstance
    .delete(`library/${id}/`)
    .then((res) => ({ data: res.data, message: 'Link has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
