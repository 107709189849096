// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Dayjs
import dayjs from 'dayjs';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleSplitBySlugs } from 'services/splits';
import { fetchTeamsStatistics } from 'services/data';

// Constants
import { HIDE_PAGINATION } from 'constants/mrt';
import { MATCH_TYPES } from 'constants/match';
import {
  SPLIT_TEAMS_STATISTICS_BATTLE_COLS,
  SPLIT_TEAMS_STATISTICS_DIFFERENCE_COLS,
  SPLIT_TEAMS_STATISTICS_GENERAL_COLS,
  SPLIT_TEAMS_STATISTICS_OBJECTIVES_COLS,
  SPLIT_TEAMS_STATISTICS_VISION_COLS,
} from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const SplitSingleTeamsStatisticsView = () => {
  const {
    setTitleSEO,
    filters: {
      split: { id, rangeDate, side, statisticTeam, patches, patches_data, timerange },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { leagueSlug, splitSlug } = useParams();
  const [split, setSplit] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (leagueValue, splitValue) => {
    setIsLoading(true);

    fetchSingleSplitBySlugs(leagueValue, splitValue).then((res) => {
      setSplit(res);
    });
  };

  const fetchData = (
    itemName,
    itemType,
    sideData,
    timerangeData,
    splitId,
    startDateData,
    endDateData,
    patchesData,
  ) => {
    setIsLoading(true);

    fetchTeamsStatistics(
      itemName,
      itemType,
      sideData,
      timerangeData,
      splitId,
      startDateData,
      endDateData,
      patchesData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        split: { ...prevValue.split, patches_data: res.patches_data },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(leagueSlug, splitSlug);
    }

    renderAfterCalled.current = true;
  }, [leagueSlug, splitSlug]);

  useEffect(() => {
    if (split) {
      fetchData(
        statisticTeam,
        MATCH_TYPES.COMPETITIVE,
        side,
        timerange,
        split.id,
        rangeDate[0],
        rangeDate[1],
        patches,
      );
    }
  }, [rangeDate, side, statisticTeam, timerange, split, patches]);

  useEffect(() => {
    if (split) {
      const tempTitle = `${split.league_name} ${split.name} Teams Statistics`;
      const startDate = split?.start_date ? dayjs(split?.start_date) : null;
      const endDate = split?.end_date ? dayjs(split?.end_date) : null;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== split.id || (rangeDate[0] === null && rangeDate[1] === null)) {
        setFilters((prevValue) => ({
          ...prevValue,
          split: {
            ...prevValue.split,
            id: split.id,
            rangeDate: [startDate, endDate],
          },
        }));
      }
    }
  }, [split]);

  const columns = useMemo(() => {
    if (statisticTeam === 'General') return SPLIT_TEAMS_STATISTICS_GENERAL_COLS;
    if (statisticTeam === 'Battle') return SPLIT_TEAMS_STATISTICS_BATTLE_COLS;
    if (statisticTeam === 'Vision') return SPLIT_TEAMS_STATISTICS_VISION_COLS;
    if (statisticTeam === 'Objectives') return SPLIT_TEAMS_STATISTICS_OBJECTIVES_COLS;
    if (statisticTeam === 'Difference') return SPLIT_TEAMS_STATISTICS_DIFFERENCE_COLS;

    return [];
  }, [statisticTeam]);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_PAGINATION,
    enableTableFooter: true,
    enableStickyFooter: true,
    muiTableFooterCellProps: { sx: { justifyContent: 'center' } },
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters
            rangeDateFilter
            sideFilter
            statisticTeamFilter
            patchesFilter={patches_data}
            timerangeFilter
            type="split"
          />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default SplitSingleTeamsStatisticsView;
