// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchJungle } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid2 as Grid } from '@mui/material';
import GameMap from 'components/molecules/GameMap/GameMap';
import GameDataInfo from 'components/molecules/GameDataInfo/GameDataInfo';

const TeamSingleJungleView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        pathType,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    pathTypeData,
    teamId,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchJungle(
      itemType,
      sideData,
      pathTypeData,
      teamId,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: {
          ...prevValue.team,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        pathType,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, pathType, team, patches, opponents, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Jungle`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            pathTypeFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="team"
          />
          <Grid container>
            {data &&
              data.map(
                ({
                  id,
                  date,
                  side: gameSide,
                  opponent: gameOpponent,
                  win,
                  path,
                  gold,
                  items,
                  events,
                  wards,
                  positions,
                  picks,
                  proximity,
                }) => (
                  <Grid key={id} size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Grid container>
                      <Grid size={{ xs: 9 }}>
                        <GameMap
                          date={date}
                          opponent={gameOpponent}
                          win={win}
                          side={gameSide}
                          positions={positions}
                          path={path}
                          wards={wards}
                          picks={picks}
                        />
                      </Grid>
                      <Grid size={{ xs: 3 }}>
                        <GameDataInfo
                          gold={gold}
                          items={items}
                          events={events}
                          proximity={proximity}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              )}
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleJungleView;
