// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Routes
import { championsQueueSingleBasicURL } from 'routes/app';

// Services
import { fetchChampionsQueues } from 'services/championsQueues';

// Constants
import { CHAMPIONS_QUEUES_COLS } from 'constants/tableHead';

// Elements
import ItemsListTemplate from 'components/molecules/ItemsListTemplate/ItemsListTemplate';

const ChampionsQueueView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Champions Queues';

  const fetchData = () => {
    setIsLoading(true);

    fetchChampionsQueues().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => CHAMPIONS_QUEUES_COLS, []);

  return (
    <ItemsListTemplate
      title={title}
      data={data}
      columns={columns}
      rowActions={{ baseLink: championsQueueSingleBasicURL, pathVariables: ['slug'] }}
    />
  );
};

export default ChampionsQueueView;
