// React
import React from 'react';

// Constants
import { BLACK_MAIN, TABLE_BACKGROUND_MAIN } from 'constants/color';
import { ROLES_LIST } from 'constants/role';

// Theme
import { scale, scoreScale } from 'theme/theme';

// Utils
import { formatDatetime, secondsToHms } from 'utils/time';
import { footerTableData, footerTableTimeData } from 'utils/data';
import { replaceWithBr } from 'utils/text';

// Constants
import { PLATES, PLATES_PER_ROLE, HERALDS, DRAGONS, BARONS, TOWERS } from 'constants/objective';
import { BLIND, COUNTER } from 'constants/pick';

// Placeholder
import placeholder from 'assets/img/placeholder.png';

// Elements
import BooleanData from 'components/atoms/BooleanData/BooleanData';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import SideData from 'components/atoms/SideData/SideData';
import IsLiveData from 'components/atoms/IsLiveData/IsLiveData';
import ResultData from 'components/atoms/ResultData/ResultData';
import VODData from 'components/atoms/VODData/VODData';
import ReplayData from 'components/atoms/ReplayData/ReplayData';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import PicksData from 'components/molecules/PicksData/PicksData';
import BansData from 'components/molecules/BansData/BansData';
import ShopData from 'components/molecules/ShopData/ShopData';
import DragonsData from 'components/molecules/DragonsData/DragonsData';
import BaronsData from 'components/molecules/BaronsData/BaronsData';
import ListData from 'components/atoms/ListData/ListData';
import TagsData from 'components/atoms/TagsData/TagsData';
import GameDurationData from 'components/atoms/GameDurationData/GameDurationData';
import DraftData from 'components/molecules/DraftData/DraftData';
import GameThresholdData from 'components/atoms/GameThresholdData/GameThresholdData';
import SummonerUsageData from 'components/organisms/SummonerUsageData/SummonerUsageData';
import PerformanceTableData from 'components/atoms/PerformanceTableData/PerformanceTableData';
import TeamData from 'components/molecules/TeamData/TeamData';
import ItemsData from 'components/molecules/ItemsData/ItemsData';
import ColumnHeader from 'components/molecules/ColumnHeader/ColumnHeader';
import SocialMediaFile from 'components/atoms/SocialMediaFile/SocialMediaFile';
import InfoTooltip from 'components/atoms/InfoTooltip/InfoTooltip';
import { Box, Typography } from '@mui/material';
import ImageListData from 'components/molecules/ImageListData/ImageListData';

const FOOTER_DATA_PROPS = (type) => ({
  /* eslint-disable */
  Footer: ({ table, column }) => footerTableData(table, column, type),
  /* eslint-enable */
});

const SET_CENTER_DATA_IN_COLUMN = {
  muiTableHeadCellProps: {
    align: 'center',
  },
  muiTableBodyCellProps: {
    align: 'center',
  },
};

const SET_FULL_WIDTH_DATA = {
  muiTableBodyCellProps: {
    sx: {
      width: '100%',
      flex: 'unset',
    },
  },
};

export const ROW_NUMBER_PROPS = {
  header: 'No.',
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 50,
};

export const ROW_EXPAND_PROPS = {
  header: '',
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 50,
};

export const ROW_ACTIONS_PROPS = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 110,
  muiTableHeadCellProps: {
    sx: {
      paddingTop: '0.75rem',
      paddingBottom: '0.6rem',
      '.Mui-TableHeadCell-Content.MuiBox-root': {
        justifyContent: 'center',
      },
    },
  },
};

export const ROW_ACTIONS_WITH_PASSWORD_PROPS = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 150,
  muiTableHeadCellProps: {
    sx: {
      paddingTop: '0.75rem',
      paddingBottom: '0.6rem',
      '.Mui-TableHeadCell-Content.MuiBox-root': {
        justifyContent: 'center',
      },
    },
  },
};

export const ROW_ACTIONS_TP_PROPS = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 175,
  muiTableHeadCellProps: {
    sx: {
      paddingTop: '0.75rem',
      paddingBottom: '0.6rem',
      '.Mui-TableHeadCell-Content.MuiBox-root': {
        justifyContent: 'center',
      },
    },
  },
};

export const ROW_ACTIONS_TEAM_FIGHTS_PROPS = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 125,
  muiTableHeadCellProps: {
    sx: {
      paddingTop: '0.75rem',
      paddingBottom: '0.6rem',
      '.Mui-TableHeadCell-Content.MuiBox-root': {
        justifyContent: 'center',
      },
    },
  },
};

const BOOLEAN_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <BooleanData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 150,
  enableGlobalFilter: false,
};

const IMAGE_CELL = {
  /* eslint-disable */
  Cell: ({
    row: { original },
    cell: {
      column: { id },
    },
  }) => {
    let name = 'Placeholder';
    let image_url = placeholder;

    if (id !== 'image') {
      const number = id.split('_')[1];

      name = original[`name_${number}`];
      image_url = original[`image_${number}`];
    } else {
      name = original.name;
      image_url = original.image_url;
    }

    return <ImageItem src={image_url} alt={name} autoHeight />;
  },
  /* eslint-enable */
  size: 81,
  enableSorting: false,
  enableGlobalFilter: false,
  enableColumnFilter: false,
  enableGrouping: false,
  muiTableHeadCellProps: {
    align: 'center',
  },
  muiTableBodyCellProps: {
    align: 'center',
    sx: {
      paddingX: 2,
      paddingY: 0.75,
    },
  },
};

const DATETIME_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => (cell.getValue() ? formatDatetime(cell.getValue()) : ''),
  enableGlobalFilter: false,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 200,
};

const DURATION_CELL = (threshold) => ({
  /* eslint-disable */
  Cell: ({ cell }) =>
    cell.getValue() ? <GameDurationData value={cell.getValue()} threshold={threshold} /> : '',
  Footer: ({ table, column }) => footerTableData(table, column, 'duration', null, threshold),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 140,
  enableGlobalFilter: false,
});

const DURATION_DIFF_CELL = {
  /* eslint-disable */
  Cell: ({ cell }) =>
    cell.getValue() !== '' ? <GameDurationData value={cell.getValue()} diff /> : '',
  Footer: ({ table, column }) => footerTableData(table, column, 'durationDiff'),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 140,
  enableGlobalFilter: false,
};

const RESULT_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <ResultData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 120,
  enableGlobalFilter: false,
  enableSorting: false,
};

const SIDE_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <SideData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 110,
  enableGlobalFilter: false,
  enableSorting: false,
};

const IS_LIVE_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <IsLiveData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 100,
  enableGlobalFilter: false,
};

const VOD_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <VODData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 110,
  enableGlobalFilter: false,
  enableSorting: false,
};

const REPLAY_CELL = {
  /* eslint-disable */
  Cell: ({
    row: {
      original: { provider_id, replay },
    },
  }) => <ReplayData gameId={replay && provider_id} />,
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 110,
  enableGlobalFilter: false,
  enableSorting: false,
};

const SOCIAL_MEDIA_FILE_CELL = {
  /* eslint-disable */
  Cell: ({
    row: {
      original: { id, filename },
    },
  }) => <SocialMediaFile id={id} filename={filename} />,
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 110,
  enableGlobalFilter: false,
  enableSorting: false,
};

const GAME_DIFF_DATA_CELL = (postFix) => ({
  /* eslint-disable */
  Cell: ({ cell }) => <GameDiffData cell={cell} postFix={postFix} />,
  Footer: ({ table, column }) => footerTableData(table, column, 'diff', postFix),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 130,
  enableGlobalFilter: false,
});

const GRUBS_DATA_CELL = {
  /* eslint-disable */
  Footer: ({ table, column }) => footerTableData(table, column, 'simple'),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 150,
  enableGlobalFilter: false,
};

const GAME_PERCENTAGE_DATA_CELL = (threshold, isBoolean, inverse) => ({
  /* eslint-disable */
  Cell: ({ cell }) => <GamePercentageData cell={cell} threshold={threshold} inverse={inverse} />,
  Footer: ({ table, column }) =>
    footerTableData(table, column, 'percentage', null, threshold, isBoolean),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 100,
  enableGlobalFilter: false,
});

const PERFORMANCE_CELL = {
  /* eslint-disable */
  Cell: ({ cell }) => <PerformanceTableData cell={cell} />,
  /* eslint-enable */
  size: 125,
  enableGlobalFilter: false,
};

const CS_PERFORMANCE_CELL = {
  ...GAME_PERCENTAGE_DATA_CELL(80),
  size: 150,
  enableGlobalFilter: false,
};

const GAME_PATCH_CELL = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 120,
  enableGlobalFilter: false,
};

const WIN_RATE_CELL = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 135,
  aggregationFn: null,
  muiTableBodyCellProps: ({ cell }) => ({
    align: 'center',
    sx: {
      color: BLACK_MAIN,
      backgroundColor: scale[Math.round(cell.getValue() / 5)],
      flex: '0 0 auto',
      'tr:hover &': {
        backgroundColor: `${scale[Math.round(cell.getValue() / 5)]} !important`,
      },
    },
  }),
};

const FORCE_2_DECIMAL_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) =>
    cell.getValue() != null && cell.getValue() !== '' && cell.getValue().toFixed(2),
  enableGlobalFilter: false,
  enableGrouping: false,
};

const PICKS_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <PicksData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 325,
  enableGlobalFilter: false,
  enableSorting: false,
};

const BANS_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <BansData cell={cell} />,
  size: 550,
  enableGlobalFilter: false,
  enableSorting: false,
};

const DRAFT_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <DraftData cell={cell} />,
  minSize: 225,
  enableGlobalFilter: false,
  enableSorting: false,
};

const SHOP_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <ShopData cell={cell} />,
  enableGlobalFilter: false,
  enableSorting: false,
  ...SET_FULL_WIDTH_DATA,
};

const ITEMS_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <ItemsData cell={cell} />,
  enableGlobalFilter: false,
  enableSorting: false,
  ...SET_FULL_WIDTH_DATA,
};

const DRAGONS_DATA_CELL = {
  /* eslint-disable */
  Cell: ({ cell }) => <DragonsData cell={cell} />,
  Footer: ({ table, column }) => footerTableTimeData(table, column),
  /* eslint-enable */
  enableGlobalFilter: false,
  size: 150,
};

const DRAGONS_SINGLE_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <DragonsData cell={cell} single />,
  enableGlobalFilter: false,
  size: 150,
};

const BARONS_DATA_CELL = {
  /* eslint-disable */
  Cell: ({ cell }) => <BaronsData cell={cell} />,
  Footer: ({ table, column }) => footerTableTimeData(table, column),
  /* eslint-enable */
  enableGlobalFilter: false,
  size: 150,
};

const BARONS_SINGLE_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <BaronsData cell={cell} single />,
  enableGlobalFilter: false,
  size: 150,
};

const LIST_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <ListData cell={cell} />,
};

const IMAGE_LIST_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <ImageListData cell={cell} />,
  size: 250,
  enableGlobalFilter: false,
  enableSorting: false,
};

const GAME_THRESHOLD_DATA_CELL = (threshold, inverse) => ({
  /* eslint-disable */
  Cell: ({ cell }) => (
    <GameThresholdData value={cell.getValue()} threshold={threshold} inverse={inverse} />
  ),
  Footer: ({ table, column }) =>
    footerTableData(table, column, 'threshold' + (inverse ? '_inverse' : ''), null, threshold),
  /* eslint-enable */
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 140,
  enableGlobalFilter: false,
});

const SCORE_CELL = {
  ...SET_CENTER_DATA_IN_COLUMN,
  size: 160,
  aggregationFn: null,
  muiTableBodyCellProps: ({ cell }) => ({
    align: 'center',
    sx: {
      color: BLACK_MAIN,
      backgroundColor: cell.getValue()
        ? scoreScale[Math.round(cell.getValue()) - 1]
        : TABLE_BACKGROUND_MAIN,
      flex: '0 0 auto',
      'tr:hover &': {
        backgroundColor: `${
          cell.getValue() ? scoreScale[Math.round(cell.getValue()) - 1] : TABLE_BACKGROUND_MAIN
        } !important`,
      },
    },
  }),
};

const SUMMONER_USAGE_DATA_CELL = (summonerName) => ({
  // eslint-disable-next-line
  Cell: ({ cell }) => <SummonerUsageData cell={cell} name={summonerName} />,
  enableGlobalFilter: false,
  enableSorting: false,
  ...SET_FULL_WIDTH_DATA,
});

const TEAM_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <TeamData cell={cell} />,
  minSize: 225,
  enableGlobalFilter: false,
  enableSorting: false,
};

const DRAFT_ORDER_DATA_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <DraftData cell={cell} />,
  ...SET_FULL_WIDTH_DATA,
  enableGlobalFilter: false,
  enableSorting: false,
};

const STRING_TIME_TO_SECONDS_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) =>
    cell.getValue() != null && cell.getValue() !== '' && secondsToHms(cell.getValue()),
  enableGlobalFilter: false,
  enableGrouping: false,
};

const TAGS_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <TagsData cell={cell} />,
  ...SET_CENTER_DATA_IN_COLUMN,
  enableGlobalFilter: false,
  enableGrouping: false,
};

const TOOLTIP_CELL = {
  // eslint-disable-next-line
  Cell: ({ cell }) => <InfoTooltip cell={cell} bigIcon />,
  ...SET_CENTER_DATA_IN_COLUMN,
  enableSorting: false,
  enableGlobalFilter: false,
  enableGrouping: false,
};

export const TEAM_MATCHES_LIST_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 130,
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 50,
    enableSorting: false,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
  },
  {
    accessorKey: 'gold_diff_7',
    header: 'GD@7',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'gold_diff_14',
    header: 'GD@14',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'dragons_diff_14',
    header: 'DRAD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'towers_diff_20',
    header: 'TD@20',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'plates_diff_7',
    header: 'PD@7',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'plates_diff_14',
    header: 'PD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs_1st',
    header: '1st GR',
    Header: () => (
      <ColumnHeader
        title="1st GR"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st Grubs
          </Typography>
        }
      />
    ),
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'team_grubs_1st_diff',
    header: '',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs_2nd',
    header: '2nd GR',
    Header: () => (
      <ColumnHeader
        title="2nd GR"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd Grubs
          </Typography>
        }
      />
    ),
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'team_grubs_2nd_diff',
    header: '',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'wards_placed_diff',
    header: 'WPD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'wards_killed_diff',
    header: 'WKD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'control_wards_diff',
    header: 'CWD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'cleared_vision_diff',
    header: 'CVD',
    ...GAME_DIFF_DATA_CELL('%'),
  },
  {
    accessorKey: 'first_blood',
    header: 'FB',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_dragon',
    header: 'FD',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_herald',
    header: 'FH',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_tower',
    header: 'FT',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_atakhan',
    header: 'FA',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
];

export const SCRIM_GAMES_LIST_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    size: 100,
    enableSorting: false,
  },
  {
    accessorKey: 'replay',
    header: 'ROFL',
    ...REPLAY_CELL,
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 40,
    enableSorting: false,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
  },
  {
    accessorKey: 'gold_diff_7',
    header: 'GD@7',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'gold_diff_14',
    header: 'GD@14',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'dragons_diff_14',
    header: 'DRAD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'towers_diff_20',
    header: 'TD@20',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'plates_diff_7',
    header: 'PD@7',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'plates_diff_14',
    header: 'PD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs_1st',
    header: '1st GR',
    Header: () => (
      <ColumnHeader
        title="1st GR"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st Grubs
          </Typography>
        }
      />
    ),
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'team_grubs_1st_diff',
    header: '',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs_2nd',
    header: '2nd GR',
    Header: () => (
      <ColumnHeader
        title="2nd GR"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd Grubs
          </Typography>
        }
      />
    ),
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'team_grubs_2nd_diff',
    header: '',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'wards_placed_diff',
    header: 'WPD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'wards_killed_diff',
    header: 'WKD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'control_wards_diff',
    header: 'CWD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'cleared_vision_diff',
    header: 'CVD',
    ...GAME_DIFF_DATA_CELL('%'),
  },
  {
    accessorKey: 'first_blood',
    header: 'FB',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_dragon',
    header: 'FD',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_herald',
    header: 'FH',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_tower',
    header: 'FT',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_atakhan',
    header: 'FA',
    ...BOOLEAN_CELL,
    size: 100,
  },
];

export const TEAM_PERFORMANCE_LIST_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'top_deaths',
    header: 'TOP D',
    Header: () => (
      <ColumnHeader
        title="TOP D"
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'jng_deaths',
    header: 'JNG D',
    Header: () => (
      <ColumnHeader
        title="JNG D"
        tooltip={
          <Typography component="p" fontSize={12}>
            JNG deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'mid_deaths',
    header: 'MID D',
    Header: () => (
      <ColumnHeader
        title="MID D"
        tooltip={
          <Typography component="p" fontSize={12}>
            MID deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'bot_deaths',
    header: 'BOT D',
    Header: () => (
      <ColumnHeader
        title="BOT D"
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'sup_deaths',
    header: 'SUP D',
    Header: () => (
      <ColumnHeader
        title="SUP D"
        tooltip={
          <Typography component="p" fontSize={12}>
            SUP deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'team_fights',
    header: 'TF',
    Header: () => (
      <ColumnHeader
        title="TF"
        tooltip={
          <Typography component="p" fontSize={12}>
            Team fights
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
    size: 90,
  },
  {
    accessorKey: 'top_cs',
    header: 'TOP',
    Header: () => (
      <ColumnHeader
        title="TOP"
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'top_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'mid_cs',
    header: 'MID',
    Header: () => (
      <ColumnHeader
        title="MID"
        tooltip={
          <Typography component="p" fontSize={12}>
            MID CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'mid_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            MID gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'bot_cs',
    header: 'BOT',
    Header: () => (
      <ColumnHeader
        title="BOT"
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'bot_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'jng_1',
    header: 'JNG 1',
    Header: () => (
      <ColumnHeader
        title="JNG 1"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st upgrade time <br />
            for smite
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'jng_1_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            1st upgrade for smite
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'jng_2',
    header: 'JNG 2',
    Header: () => (
      <ColumnHeader
        title="JNG 2"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd upgrade time <br />
            for smite
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'jng_2_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            2nd upgrade for smite
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'sup_1',
    header: 'SUP 1',
    Header: () => (
      <ColumnHeader
        title="SUP 1"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st upgrade time <br />
            for support item
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'sup_1_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            1st upgrade for support item
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'sup_2',
    header: 'SUP 2',
    Header: () => (
      <ColumnHeader
        title="SUP 2"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd upgrade time <br />
            for support item
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'sup_2_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            2nd upgrade for support item
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'top_lvl6',
    header: 'TOP LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'top_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'jng_lvl6',
    header: 'JNG LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'jng_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'mid_lvl6',
    header: 'MID LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'mid_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'bot_lvl6',
    header: 'BOT LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'bot_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'sup_lvl6',
    header: 'SUP LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'sup_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
];

export const SCRIM_PERFORMANCE_LIST_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    size: 100,
    enableSorting: false,
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 160,
    enableSorting: false,
  },
  {
    accessorKey: 'top_deaths',
    header: 'TOP D',
    Header: () => (
      <ColumnHeader
        title="TOP D"
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'jng_deaths',
    header: 'JNG D',
    Header: () => (
      <ColumnHeader
        title="JNG D"
        tooltip={
          <Typography component="p" fontSize={12}>
            JNG deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'mid_deaths',
    header: 'MID D',
    Header: () => (
      <ColumnHeader
        title="MID D"
        tooltip={
          <Typography component="p" fontSize={12}>
            MID deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'bot_deaths',
    header: 'BOT D',
    Header: () => (
      <ColumnHeader
        title="BOT D"
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'sup_deaths',
    header: 'SUP D',
    Header: () => (
      <ColumnHeader
        title="SUP D"
        tooltip={
          <Typography component="p" fontSize={12}>
            SUP deaths
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
  },
  {
    accessorKey: 'team_fights',
    header: 'TF',
    Header: () => (
      <ColumnHeader
        title="TF"
        tooltip={
          <Typography component="p" fontSize={12}>
            Team fights
          </Typography>
        }
      />
    ),
    ...PERFORMANCE_CELL,
    size: 90,
  },
  {
    accessorKey: 'top_cs',
    header: 'TOP',
    Header: () => (
      <ColumnHeader
        title="TOP"
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'top_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            TOP gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'mid_cs',
    header: 'MID',
    Header: () => (
      <ColumnHeader
        title="MID"
        tooltip={
          <Typography component="p" fontSize={12}>
            MID CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'mid_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            MID gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'bot_cs',
    header: 'BOT',
    Header: () => (
      <ColumnHeader
        title="BOT"
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT CS% of perfect <br />
            from 9 waves
          </Typography>
        }
      />
    ),
    ...CS_PERFORMANCE_CELL,
  },
  {
    accessorKey: 'bot_gold',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            BOT gold diff from <br />
            perfect 9 waves
          </Typography>
        }
      />
    ),
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'jng_1',
    header: 'JNG 1',
    Header: () => (
      <ColumnHeader
        title="JNG 1"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st upgrade time <br />
            for smite
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'jng_1_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            1st upgrade for smite
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'jng_2',
    header: 'JNG 2',
    Header: () => (
      <ColumnHeader
        title="JNG 2"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd upgrade time <br />
            for smite
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'jng_2_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            2nd upgrade for smite
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'sup_1',
    header: 'SUP 1',
    Header: () => (
      <ColumnHeader
        title="SUP 1"
        tooltip={
          <Typography component="p" fontSize={12}>
            1st upgrade time <br />
            for support item
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'sup_1_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            1st upgrade for support item
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'sup_2',
    header: 'SUP 2',
    Header: () => (
      <ColumnHeader
        title="SUP 2"
        tooltip={
          <Typography component="p" fontSize={12}>
            2nd upgrade time <br />
            for support item
          </Typography>
        }
      />
    ),
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'sup_2_diff',
    header: '',
    Header: () => (
      <ColumnHeader
        title=""
        tooltip={
          <Typography component="p" fontSize={12}>
            Diff time enemy <br />
            2nd upgrade for support item
          </Typography>
        }
      />
    ),
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 'top_lvl6',
    header: 'TOP LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'top_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'jng_lvl6',
    header: 'JNG LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'jng_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'mid_lvl6',
    header: 'MID LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'mid_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'bot_lvl6',
    header: 'BOT LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'bot_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
  {
    accessorKey: 'sup_lvl6',
    header: 'SUP LvL6',
    ...DURATION_CELL(),
    size: 150,
  },
  {
    accessorKey: 'sup_lvl6_diff',
    header: '',
    ...DURATION_DIFF_CELL,
    size: 150,
  },
];

export const TEAM_SCORE_LIST_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 267,
  },
  {
    accessorKey: 'top',
    header: 'TOP',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'jng',
    header: 'JNG',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'sup',
    header: 'SUP',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const SCRIM_SCORE_LIST_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 317,
  },
  {
    accessorKey: 'top',
    header: 'TOP',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'jng',
    header: 'JNG',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'sup',
    header: 'SUP',
    ...SCORE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const COMPETITIVES_LIST_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image_1',
    header: 'Team 1',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'image_2',
    header: 'Team 2',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 225,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
  },
  {
    accessorKey: 'gold_diff_7',
    header: 'GD@7',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'gold_diff_14',
    header: 'GD@14',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'dragons_diff_14',
    header: 'DRAD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'towers_diff_20',
    header: 'TD@20',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'plates_diff_7',
    header: 'PD@7',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'plates_diff_14',
    header: 'PD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs',
    header: 'TGR',
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'enemy_grubs',
    header: 'EGR',
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'wards_placed_diff',
    header: 'WPD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'wards_killed_diff',
    header: 'WKD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'control_wards_diff',
    header: 'CWD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'cleared_vision_diff',
    header: 'CVD',
    ...GAME_DIFF_DATA_CELL('%'),
  },
  {
    accessorKey: 'first_blood',
    header: 'FB',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_dragon',
    header: 'FD',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_herald',
    header: 'FH',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
  {
    accessorKey: 'first_tower',
    header: 'FT',
    ...GAME_PERCENTAGE_DATA_CELL(40, true),
  },
];

export const COMPETITIVE_GAMES_LIST_COLS = [
  {
    accessorKey: 'image_1',
    header: 'Blue',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'image_2',
    header: 'Red',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 155,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'replay',
    header: 'ROFL',
    ...REPLAY_CELL,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
  },
  {
    accessorKey: 'gold_diff_7',
    header: 'GD@7',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'gold_diff_14',
    header: 'GD@14',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'dragons_diff_14',
    header: 'DRAD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'towers_diff_20',
    header: 'TD@20',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'plates_diff_7',
    header: 'PD@7',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'plates_diff_14',
    header: 'PD@14',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'team_grubs',
    header: 'TGR',
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'enemy_grubs',
    header: 'EGR',
    ...GRUBS_DATA_CELL,
  },
  {
    accessorKey: 'wards_placed_diff',
    header: 'WPD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'wards_killed_diff',
    header: 'WKD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'control_wards_diff',
    header: 'CWD',
    ...GAME_DIFF_DATA_CELL(),
  },
  {
    accessorKey: 'cleared_vision_diff',
    header: 'CVD',
    ...GAME_DIFF_DATA_CELL('%'),
  },
  {
    accessorKey: 'first_blood',
    header: 'FB',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_dragon',
    header: 'FD',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_herald',
    header: 'FH',
    ...BOOLEAN_CELL,
    size: 100,
  },
  {
    accessorKey: 'first_tower',
    header: 'FT',
    ...BOOLEAN_CELL,
    size: 100,
  },
];

export const CHAMPION_POOL_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'count',
    header: 'G',
    ...SET_CENTER_DATA_IN_COLUMN,
    minSize: 80,
    maxSize: 85,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
    minSize: 90,
    maxSize: 100,
  },
];

export const CHAMPION_DRAFT_STATS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Champion',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'presence',
    header: 'PB%',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    ...FORCE_2_DECIMAL_CELL,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'pick_rate',
    header: 'PR%',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'count',
    header: 'Picks',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'pick_1',
    header: '1st phase',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 140,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'pick_2',
    header: '2nd phase',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 140,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'bans',
    header: 'Bans',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'ban_1',
    header: '1st phase',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 140,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'ban_2',
    header: '2nd phase',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 140,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    size: 140,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'win_rate_1',
    header: '1st phase',
    ...WIN_RATE_CELL,
    size: 140,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'win_rate_2',
    header: '2nd phase',
    ...WIN_RATE_CELL,
    size: 140,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const CHAMPIONS_QUEUE_CHAMPION_DRAFT_STATS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Champion',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'count',
    header: 'Picks',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    size: 140,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const CHAMPION_POOL_FULL_STATS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Champion',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'count',
    header: 'Picks',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    size: 150,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'gd14',
    header: 'GD@14',
    ...GAME_DIFF_DATA_CELL(),
  },
];

export const CHAMPION_SYNERGIES_COLS = [
  {
    accessorKey: 'image_1',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'image_2',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name_1',
    header: 'Champion 1',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 250,
  },
  {
    accessorKey: 'name_2',
    header: 'Champion 2',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 250,
  },
  {
    accessorKey: 'pick_rate',
    header: 'PR%',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    ...FORCE_2_DECIMAL_CELL,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
    enableGrouping: false,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    size: 150,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const CHAMPION_PICKS_COLS = (pick) => {
  const staticCols = [
    {
      accessorKey: 'image',
      header: '',
      ...IMAGE_CELL,
    },
    {
      accessorKey: 'name',
      header: 'Pick',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 150,
    },
    {
      accessorKey: 'count',
      header: 'Games',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 150,
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'win_rate',
      header: 'WR%',
      ...WIN_RATE_CELL,
      size: 150,
      ...FORCE_2_DECIMAL_CELL,
    },
  ];
  const bansCols = [
    {
      accessorKey: 'bans',
      header: 'Enemy bans',
      ...BANS_DATA_CELL,
    },
  ];

  if (pick === BLIND || pick === COUNTER) {
    return [
      ...staticCols,
      {
        accessorKey: 'picks',
        header: 'Enemy picks',
        ...PICKS_DATA_CELL,
      },
      ...bansCols,
    ];
  }

  return [
    ...staticCols,
    {
      accessorKey: 'blind_picks',
      header: 'Enemy blind picks',
      ...PICKS_DATA_CELL,
    },
    {
      accessorKey: 'counter_picks',
      header: 'Enemy counter picks',
      ...PICKS_DATA_CELL,
    },
    ...bansCols,
  ];
};

export const CHAMPION_ENEMY_DATA_COLS = (picksData) => {
  let cols = [
    {
      accessorKey: 'image',
      header: '',
      ...IMAGE_CELL,
    },
    {
      accessorKey: 'name',
      header: picksData ? 'Enemy pick' : 'Ban',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 150,
    },
    {
      accessorKey: 'count',
      header: 'Games',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 150,
      enableGlobalFilter: false,
    },
  ];

  if (picksData) {
    cols = [
      ...cols,
      {
        accessorKey: 'win_rate',
        header: 'Team WR%',
        ...WIN_RATE_CELL,
        size: 150,
        ...FORCE_2_DECIMAL_CELL,
      },
      {
        accessorKey: 'blind_picks',
        header: 'Team blind picks',
        ...PICKS_DATA_CELL,
      },
      {
        accessorKey: 'counter_picks',
        header: 'Team counter picks',
        ...PICKS_DATA_CELL,
      },
      {
        accessorKey: 'bans',
        header: 'Enemy bans',
        ...BANS_DATA_CELL,
      },
    ];
  } else {
    cols = [
      ...cols,
      {
        accessorKey: 'ban_rate',
        header: 'BR%',
        ...GAME_PERCENTAGE_DATA_CELL(),
        size: 150,
      },
      {
        accessorKey: 'abt',
        header: 'ABT',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 125,
        ...FORCE_2_DECIMAL_CELL,
      },
      {
        accessorKey: 'ban_phase_1',
        header: '1st phase',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 150,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_phase_2',
        header: '2nd phase',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 150,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_1',
        header: 'B1',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_2',
        header: 'B2',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_3',
        header: 'B3',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_4',
        header: 'B4',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_5',
        header: 'B5',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ban_6',
        header: 'B6',
        ...SET_CENTER_DATA_IN_COLUMN,
        size: 110,
        enableGlobalFilter: false,
      },
    ];
  }

  return cols;
};

export const SPLITS_COLS = [
  {
    accessorKey: 'league_name',
    header: 'League',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const SPLIT_TEAMS_STATISTICS_GENERAL_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    minSize: 200,
  },
  {
    accessorKey: 'kills',
    header: 'K',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'deaths',
    header: 'D',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'assists',
    header: 'A',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'plates',
    header: 'Plates',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SPLIT_TEAMS_STATISTICS_BATTLE_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    minSize: 200,
  },
  {
    accessorKey: 'cs',
    header: 'CS',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'xp',
    header: 'XP',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'gold',
    header: 'G',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'dmg',
    header: 'DMG',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SPLIT_TEAMS_STATISTICS_VISION_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    minSize: 200,
  },
  {
    accessorKey: 'wards_placed',
    header: 'WP',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'wards_killed',
    header: 'WK',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'control_wards_placed',
    header: 'CW',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'cleared_vision',
    header: 'CV%',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SPLIT_TEAMS_STATISTICS_OBJECTIVES_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    minSize: 200,
  },
  {
    accessorKey: 'towers',
    header: 'Towers',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'grubs',
    header: 'Grubs',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'dragons',
    header: 'Dragons',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'heralds',
    header: 'Heralds',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'barons',
    header: 'Barons',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SPLIT_TEAMS_STATISTICS_DIFFERENCE_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    minSize: 200,
  },
  {
    accessorKey: 'cs_diff',
    header: 'CSD',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'xp_diff',
    header: 'XPD',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'gold_diff',
    header: 'GD',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'dmg_diff',
    header: 'DMGD',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SPLIT_PLAYERS_STATISTICS_COLS = () => {
  const startList = [
    {
      accessorKey: 'image',
      header: '',
      ...IMAGE_CELL,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      minSize: 200,
    },
  ];

  const stats = {
    kills: 'K',
    deaths: 'D',
    assists: 'A',
    // kp: 'KP%',
    cs: 'CS',
    xp: 'XP',
    gold: 'G',
    dmg: 'DMG',
    dmg_taken: 'DMG T',
    plates: 'Plates',
    vision_score: 'VS',
    wards_placed: 'WP',
    wards_killed: 'WK',
    control_wards_placed: 'CW',
    solo_kills: 'SOLO',
    isolated_deaths: 'ISOLATED',
    // kills_deaths_ratio: 'K/D',
    // dmg_gold_ratio: 'DMG/GOLD',
    recalls: 'RECALLS',
    // top_proximity: 'TOP PROX',
    // jng_proximity: 'JNG PROX',
    // mid_proximity: 'MID PROX',
    // bot_proximity: 'BOT PROX',
    // sup_proximity: 'SUP PROX',
    // lane_aggressive: 'LA',
    // lane_neutral: 'LN',
    // lane_defensive: 'LD',
    // forward: 'FWD',
    // counter_jungle: 'CJ',
  };

  Object.keys(stats).forEach((key) => {
    startList.push({
      accessorKey: key,
      header: stats[key],
      ...FOOTER_DATA_PROPS('simple'),
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 150,
      enableGlobalFilter: false,
    });
  });

  return startList;
};

export const SOLOQ_LP_BY_TEAMS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Team',
  },
  {
    accessorKey: 'count',
    header: 'Games',
    size: 150,
  },
  {
    accessorKey: 'lp',
    header: 'Points',
    size: 150,
  },
  {
    accessorKey: 'diff',
    header: 'LP Gain',
    ...GAME_DIFF_DATA_CELL(),
  },
];

export const SPLIT_PLAYERS_LP_GAIN_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'player',
    header: 'Player',
  },
  {
    accessorKey: 'count',
    header: 'Games',
    size: 150,
  },
  {
    accessorKey: 'lp',
    header: 'LP Gain',
    ...GAME_DIFF_DATA_CELL(),
  },
];

export const CHAMPIONS_QUEUES_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const TEAMS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'leagues_names',
    header: 'Leagues',
    filterVariant: 'autocomplete',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
    enableGlobalFilter: false,
  },
];

export const TEAM_FLEX_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Champion',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'data.TOP',
    header: 'TOP',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'data.JNG',
    header: 'JNG',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'data.MID',
    header: 'MID',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'data.BOT',
    header: 'BOT',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'data.SUP',
    header: 'SUP',
    ...BOOLEAN_CELL,
  },
];

export const TEAM_JUNGLE_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 148,
  },
  {
    accessorKey: 'smite',
    header: 'Smite',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'level',
    header: 'LvL',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 100,
  },
  {
    accessorKey: 'time',
    header: 'Time',
    ...DURATION_CELL(),
  },
];

export const TEAM_JUNGLE_STATISTICS_PROXIMITY_COLS = [
  {
    accessorKey: 'name',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 75,
    enableSorting: false,
  },
  {
    accessorKey: 'value',
    header: 'Value',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 125,
  },
  {
    accessorKey: 'diff',
    header: 'Diff',
    ...GAME_DIFF_DATA_CELL(),
  },
];

export const TEAM_JUNGLE_STATISTICS_CAMPS_COLS = [
  {
    accessorKey: 'name',
    header: '',
    enableSorting: false,
  },
  {
    accessorKey: 'value',
    header: 'Value',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 130,
  },
];

export const TEAM_MATCHES_SHOP_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
  },
];

export const TEAM_GAMES_SHOP_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'image_1',
    header: 'Champion',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'image_2',
    header: 'Enemy',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'shop',
    header: 'Shop',
    ...SHOP_DATA_CELL,
  },
];

export const PLATES_COLS = [
  {
    accessorKey: 'all',
    header: 'ALL',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'diff',
    header: 'DIFF',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'empty',
    header: 'EMPTY',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'top',
    header: 'TOP',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'top_diff',
    header: 'TOP diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'mid_diff',
    header: 'MID diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'bot_diff',
    header: 'BOT diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
];

export const PLATES_PER_ROLE_COLS = [
  {
    accessorKey: 'top',
    header: 'TOP',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'top_diff',
    header: 'TOP diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'jng',
    header: 'JNG',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'jng_diff',
    header: 'JNG diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'mid_diff',
    header: 'MID diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'bot_diff',
    header: 'BOT diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
  {
    accessorKey: 'sup',
    header: 'SUP',
    ...FOOTER_DATA_PROPS('simple'),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'sup_diff',
    header: 'SUP diff',
    ...GAME_DIFF_DATA_CELL(),
    size: 150,
  },
];

export const HERALDS_COLS = (games) => {
  let colSingle = GAME_PERCENTAGE_DATA_CELL(40, true);

  if (games) {
    colSingle = BOOLEAN_CELL;
  }

  return [
    {
      accessorKey: 'herald_1',
      header: '1st',
      ...colSingle,
      size: 120,
    },
    {
      accessorKey: 'herald_1_time',
      header: 'Time',
      ...DURATION_CELL(),
      size: 120,
    },
  ];
};

export const DRAGONS_COLS = (games, number) => {
  let colList = DRAGONS_DATA_CELL;

  if (games) {
    colList = DRAGONS_SINGLE_DATA_CELL;
  }

  const dataList = [];

  for (let i = 1; i <= number; i += 1) {
    dataList.push({
      accessorKey: `dragon_${i}`,
      header: `Dragon ${i}`,
      ...colList,
      enableSorting: false,
    });
  }

  return dataList;
};

export const BARONS_COLS = (games, number) => {
  let colList = BARONS_DATA_CELL;

  if (games) {
    colList = BARONS_SINGLE_DATA_CELL;
  }

  const dataList = [];

  for (let i = 1; i <= number; i += 1) {
    dataList.push({
      accessorKey: `baron_${i}`,
      header: `Baron ${i}`,
      ...colList,
      enableSorting: false,
    });
  }

  return dataList;
};

export const TOWERS_COLS = [
  {
    accessorKey: 't1top',
    header: 'T1 TOP',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't1top_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 't1mid',
    header: 'T1 MID',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't1mid_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 't1bot',
    header: 'T1 BOT',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't1bot_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 't2top',
    header: 'T2 TOP',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't2top_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 't2mid',
    header: 'T2 MID',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't2mid_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
  {
    accessorKey: 't2bot',
    header: 'T2 BOT',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 't2bot_diff',
    header: '',
    ...DURATION_DIFF_CELL,
  },
];

export const TEAM_MATCHES_OBJECTIVE_COLS = (objectiveType, number = null) => {
  const staticCols = [
    {
      accessorKey: 'date',
      header: 'Date',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'image',
      header: 'Opponent',
      ...IMAGE_CELL,
      size: 150,
    },
    {
      accessorKey: 'result',
      header: 'Result',
      ...RESULT_CELL,
    },
    {
      accessorKey: 'score',
      header: 'Score',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
      enableGlobalFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'patch',
      header: 'Patch',
      ...GAME_PATCH_CELL,
    },
  ];

  if (objectiveType === PLATES) {
    return [...staticCols, ...PLATES_COLS];
  }

  if (objectiveType === PLATES_PER_ROLE) {
    return [...staticCols, ...PLATES_PER_ROLE_COLS];
  }

  if (objectiveType === HERALDS) {
    return [...staticCols, ...HERALDS_COLS(false)];
  }

  if (objectiveType === DRAGONS) {
    return [...staticCols, ...DRAGONS_COLS(false, number)];
  }

  if (objectiveType === BARONS) {
    return [...staticCols, ...BARONS_COLS(false, number)];
  }

  if (objectiveType === TOWERS) {
    return [...staticCols, ...TOWERS_COLS];
  }

  return [...staticCols];
};

export const TEAM_GAMES_OBJECTIVE_COLS = (objectiveType, number = null) => {
  const staticCols = [
    {
      accessorKey: 'result',
      header: 'Result',
      ...RESULT_CELL,
    },
    {
      accessorKey: 'side',
      header: 'Side',
      ...SIDE_CELL,
    },
    {
      accessorKey: 'duration',
      header: 'Duration',
      ...DURATION_CELL(),
    },
    {
      accessorKey: 'surrender',
      header: 'FF',
      ...BOOLEAN_CELL,
      enableSorting: false,
    },
    {
      accessorKey: 'empty_col',
      header: '',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 40,
      enableSorting: false,
    },
  ];

  if (objectiveType === PLATES) {
    return [...staticCols, ...PLATES_COLS];
  }

  if (objectiveType === PLATES_PER_ROLE) {
    return [...staticCols, ...PLATES_PER_ROLE_COLS];
  }

  if (objectiveType === HERALDS) {
    return [...staticCols, ...HERALDS_COLS(true)];
  }

  if (objectiveType === DRAGONS) {
    return [...staticCols, ...DRAGONS_COLS(true, number)];
  }

  if (objectiveType === BARONS) {
    return [...staticCols, ...BARONS_COLS(true, number)];
  }

  if (objectiveType === TOWERS) {
    return [...staticCols, ...TOWERS_COLS];
  }

  return [...staticCols];
};

export const TEAM_MATCHES_SUMMONER_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 150,
  },
  {
    accessorKey: 'usage',
    header: 'Usage',
    enableSorting: false,
  },
];

export const TEAM_GAMES_SUMMONER_COLS = (summonerName) => [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 70,
    enableSorting: false,
  },
  {
    accessorKey: 'usage',
    header: 'Usage',
    ...SUMMONER_USAGE_DATA_CELL(summonerName),
  },
];

export const TEAM_TP_FLANKS_COLS = [
  {
    accessorKey: 'timer',
    header: 'Timer',
    /* eslint-disable */
    Cell: ({ cell }) => (cell.getValue() ? <GameDurationData value={cell.getValue()} /> : ''),
    /* eslint-enable */
    enableGlobalFilter: false,
  },
];

export const TEAM_GAME_HOURS_COLS = [
  {
    accessorKey: 'hour',
    header: 'Hour',
  },
  {
    accessorKey: 'empty',
    header: '',
    size: 40,
    enableSorting: false,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'g1',
    header: 'G1',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g2',
    header: 'G2',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g3',
    header: 'G3',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g4',
    header: 'G4',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g5',
    header: 'G5',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g6',
    header: 'G6',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const TEAM_GAME_HOURS_SINGLE_COLS = [
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'empty',
    header: '',
    size: 70,
    enableSorting: false,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'g1',
    header: 'G1',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g2',
    header: 'G2',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g3',
    header: 'G3',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g4',
    header: 'G4',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g5',
    header: 'G5',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'g6',
    header: 'G6',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const TEAM_WARDS_STATISTICS_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'top_1',
    header: 'TOP',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'top_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'top_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'top_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'top_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'jng_1',
    header: 'JNG',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'jng_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'jng_cw',
    header: 'CW',
    ...DURATION_CELL(420),
  },
  {
    accessorKey: 'jng_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(3),
  },
  {
    accessorKey: 'jng_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'mid_1',
    header: 'MID',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'mid_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'mid_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'mid_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'mid_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'bot_1',
    header: 'BOT',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'bot_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'bot_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'bot_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'bot_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'sup_1',
    header: 'SUP',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'sup_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'sup_cw',
    header: 'CW',
    ...DURATION_CELL(420),
  },
  {
    accessorKey: 'sup_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(6),
  },
  {
    accessorKey: 'sup_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(3),
  },
];

export const TEAM_GAMES_WARDS_STATISTICS_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'empty',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableSorting: false,
  },
  {
    accessorKey: 'top_1',
    header: 'TOP',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'top_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'top_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'top_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'top_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'jng_1',
    header: 'JNG',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'jng_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'jng_cw',
    header: 'CW',
    ...DURATION_CELL(420),
  },
  {
    accessorKey: 'jng_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(3),
  },
  {
    accessorKey: 'jng_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'mid_1',
    header: 'MID',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'mid_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'mid_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'mid_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'mid_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'bot_1',
    header: 'BOT',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'bot_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'bot_cw',
    header: 'CW',
    ...DURATION_CELL(600),
  },
  {
    accessorKey: 'bot_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(2),
  },
  {
    accessorKey: 'bot_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(1),
  },
  {
    accessorKey: 'sup_1',
    header: 'SUP',
    ...DURATION_CELL(150),
  },
  {
    accessorKey: 'sup_2',
    header: '',
    ...DURATION_CELL(300),
  },
  {
    accessorKey: 'sup_cw',
    header: 'CW',
    ...DURATION_CELL(420),
  },
  {
    accessorKey: 'sup_cwc',
    header: 'CWC',
    ...GAME_THRESHOLD_DATA_CELL(6),
  },
  {
    accessorKey: 'sup_cw20',
    header: 'CW@20',
    ...GAME_THRESHOLD_DATA_CELL(3),
  },
];

export const TEAM_CONTROL_WARDS_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'top',
    header: 'TOP',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_top',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'jng',
    header: 'JNG',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_jng',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_mid',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_bot',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'sup',
    header: 'SUP',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_sup',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
];

export const TEAM_GAMES_CONTROL_WARDS_COLS = [
  {
    accessorKey: 'result',
    header: 'Result',
    ...RESULT_CELL,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'empty',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 110,
    enableSorting: false,
  },
  {
    accessorKey: 'top',
    header: 'TOP',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_top',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'jng',
    header: 'JNG',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_jng',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'mid',
    header: 'MID',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_mid',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'bot',
    header: 'BOT',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_bot',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'sup',
    header: 'SUP',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
  {
    accessorKey: 'enemy_sup',
    header: '',
    ...GAME_THRESHOLD_DATA_CELL(1, true),
  },
];

export const PLAYERS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    filterVariant: 'multi-select',
    filterSelectOptions: ROLES_LIST,
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'teams_names',
    header: 'Teams',
    filterVariant: 'autocomplete',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'leagues_names',
    header: 'Leagues',
    filterVariant: 'autocomplete',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
    enableGlobalFilter: false,
  },
];

export const PLAYER_CHAMPION_POOL_COLS = (matchup) => {
  const otherCols = [
    {
      accessorKey: 'count',
      header: 'G',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 105,
    },
    {
      accessorKey: 'win_rate',
      header: 'WR%',
      ...WIN_RATE_CELL,
      ...FORCE_2_DECIMAL_CELL,
    },
    {
      accessorKey: 'kills',
      header: 'K',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'deaths',
      header: 'D',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'assists',
      header: 'A',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'kp',
      header: 'KP%',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'cspm',
      header: 'CSPM',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'gpm',
      header: 'GPM',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'dpm',
      header: 'DPM',
      ...FORCE_2_DECIMAL_CELL,
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 120,
    },
    {
      accessorKey: 'csd14',
      header: 'CSD@14',
      ...GAME_DIFF_DATA_CELL(),
      size: 150,
    },
    {
      accessorKey: 'gd14',
      header: 'GD@14',
      ...GAME_DIFF_DATA_CELL(),
      size: 150,
    },
    {
      accessorKey: 'xpd14',
      header: 'XPD@14',
      ...GAME_DIFF_DATA_CELL(),
      size: 150,
    },
  ];

  let firstCols = [
    {
      accessorKey: 'image',
      header: '',
      ...IMAGE_CELL,
    },
    {
      accessorKey: 'empty',
      header: '',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 80,
      enableSorting: false,
    },
  ];

  if (matchup) {
    firstCols = [
      {
        accessorKey: 'image_1',
        header: '',
        ...IMAGE_CELL,
      },
      {
        accessorKey: 'image_2',
        header: '',
        ...IMAGE_CELL,
      },
    ];
  }

  return [...firstCols, ...otherCols];
};

export const PLAYER_STATISTICS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'value',
    header: 'Value',
    ...FORCE_2_DECIMAL_CELL,
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'diff',
    header: 'Diff',
    ...GAME_DIFF_DATA_CELL(),
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
];

export const PLAYER_GAME_HOURS_COLS = [
  {
    accessorKey: 'hour',
    header: 'Hour',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const PLAYER_GAME_HOURS_SINGLE_COLS = [
  {
    accessorKey: 'image',
    header: 'Champion',
    ...IMAGE_CELL,
    size: 150,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const CHAMPIONS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ACCOUNTS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'tag',
    header: 'Tag',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'server_name',
    header: 'Server',
    filterVariant: 'multi-select',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const SEARCH_VOD_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 130,
  },
  {
    accessorKey: 'league',
    header: 'League',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 160,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'blue',
    header: 'BLUE',
    ...DRAFT_DATA_CELL,
  },
  {
    accessorKey: 'red',
    header: 'RED',
    ...DRAFT_DATA_CELL,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
  },
  {
    accessorKey: 'replay',
    header: 'ROFL',
    ...REPLAY_CELL,
  },
];

export const SEARCH_COUNTER_COLS = [
  {
    accessorKey: 'team',
    header: 'Team',
    ...TEAM_DATA_CELL,
  },
  {
    accessorKey: 'count',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'win_rate',
    header: 'WR%',
    ...WIN_RATE_CELL,
    ...FORCE_2_DECIMAL_CELL,
  },
];

export const SEARCH_CHAMPIONS_DATA_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    size: 150,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 100,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'items',
    header: 'Items',
    Header: () => (
      <ColumnHeader
        title="Items"
        tooltip={
          <>
            <Typography component="p" fontSize={12} fontWeight="bold">
              5 popular items <br />
              for champions from 2018
            </Typography>
            <Box mt={0.5}>
              <Typography component="p" fontSize={12}>
                Data only shows if selected item:
              </Typography>
              <Typography component="p" fontSize={12}>
                - has more than 10% buy rate
              </Typography>
              <Typography component="p" fontSize={12}>
                - has occurred at least 10x
              </Typography>
            </Box>
          </>
        }
      />
    ),
    ...ITEMS_DATA_CELL,
  },
];

export const SEARCH_WORLDS_MATCHUPS_COLS = (matchup) => {
  const otherCols = [
    {
      accessorKey: 'count',
      header: 'G',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 105,
    },
    {
      accessorKey: 'win_rate',
      header: 'WR%',
      ...WIN_RATE_CELL,
      ...FORCE_2_DECIMAL_CELL,
    },
  ];

  let firstCols = [
    {
      accessorKey: 'image',
      header: 'Champion',
      ...IMAGE_CELL,
      size: 100,
    },
    {
      accessorKey: 'empty',
      header: '',
      ...SET_CENTER_DATA_IN_COLUMN,
      size: 100,
      enableSorting: false,
    },
  ];

  if (matchup) {
    firstCols = [
      {
        accessorKey: 'image_1',
        header: 'Champion',
        ...IMAGE_CELL,
        size: 100,
      },
      {
        accessorKey: 'image_2',
        header: 'Enemy',
        ...IMAGE_CELL,
        size: 100,
      },
    ];
  }

  return [...firstCols, ...otherCols];
};

export const EDITOR_DRAFT_COLS = [
  {
    accessorKey: 'datetime',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 150,
  },
  {
    accessorKey: 'blitz',
    header: 'Blitz',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'draft',
    header: 'Draft',
    ...DRAFT_ORDER_DATA_CELL,
  },
];

export const GAME_TEAM_FIGHTS_COLS = [
  {
    accessorKey: 'start_time',
    header: 'Start',
    /* eslint-disable */
    Cell: ({ cell }) => (cell.getValue() ? <GameDurationData value={cell.getValue()} /> : ''),
    /* eslint-enable */
    enableGlobalFilter: false,
    enableSorting: false,
    size: 100,
  },
  {
    accessorKey: 'end_time',
    header: 'End',
    /* eslint-disable */
    Cell: ({ cell }) => (cell.getValue() ? <GameDurationData value={cell.getValue()} /> : ''),
    /* eslint-enable */
    enableGlobalFilter: false,
    enableSorting: false,
    size: 100,
  },
];

export const GAME_TEAM_FIGHT_DATA_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'player',
    header: 'Player',
    size: 100,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
    enableSorting: true,
  },
  {
    accessorKey: 'died',
    header: 'Died',
    ...BOOLEAN_CELL,
  },
  {
    accessorKey: 'dmg',
    header: 'Dmg',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 125,
  },
  {
    accessorKey: 'dmg_taken',
    header: 'Taken',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 125,
  },
  {
    accessorKey: 'hp_percentage',
    header: 'HP%',
    ...GAME_PERCENTAGE_DATA_CELL(80),
    size: 125,
  },
  {
    accessorKey: 'gold_in_pocket',
    header: 'Gold%',
    ...GAME_PERCENTAGE_DATA_CELL(15, false, true),
    size: 125,
  },
];

export const REPLAYS_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'game_name',
    header: 'Name',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableSorting: false,
  },
  {
    accessorKey: 'image_1',
    header: 'Blue',
    ...IMAGE_CELL,
    size: 120,
  },
  {
    accessorKey: 'image_2',
    header: 'Red',
    ...IMAGE_CELL,
    size: 120,
  },
  {
    accessorKey: 'replay',
    header: 'ROFL',
    ...REPLAY_CELL,
  },
];

export const SOCIAL_MEDIA_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'game_name',
    header: 'Name',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableSorting: false,
  },
  {
    accessorKey: 'image_1',
    header: 'Blue',
    ...IMAGE_CELL,
    size: 120,
  },
  {
    accessorKey: 'image_2',
    header: 'Red',
    ...IMAGE_CELL,
    size: 120,
  },
  {
    accessorKey: 'file',
    header: 'File',
    ...SOCIAL_MEDIA_FILE_CELL,
  },
];

export const PERFORMANCE_REPORT_ATE_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'ate_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];

export const PERFORMANCE_REPORT_ATE_DETAIL_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 50,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'ate_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];

export const PERFORMANCE_REPORT_SPORT_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'sport_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];

export const PERFORMANCE_REPORT_SPORT_DETAIL_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 50,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'sport_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];
export const PERFORMANCE_REPORT_SNACK_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'snack_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];

export const PERFORMANCE_REPORT_SNACK_DETAIL_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'empty_col',
    header: '',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 50,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_count',
    header: 'Y',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_not_count',
    header: 'N',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 95,
    enableSorting: false,
  },
  {
    accessorKey: 'snack_not_wr',
    header: 'WR',
    ...WIN_RATE_CELL,
    size: 95,
    enableSorting: false,
  },
];

export const SCOUTING_SOLOQ_COLS = [
  {
    accessorKey: 'index',
    header: '#',
    size: 100,
    enableSorting: false,
  },
  {
    accessorKey: 'player',
    header: 'Player',
  },
  {
    accessorKey: 'age_end',
    header: 'Age',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 100,
  },
  {
    accessorKey: 'games_played',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'unique_champion_count',
    header: 'Unique champions',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
  },
  {
    accessorKey: 'early',
    header: '0-7',
    ...SCORE_CELL,
  },
  {
    accessorKey: 'mid',
    header: '7-14',
    ...SCORE_CELL,
  },
  {
    accessorKey: 'late',
    header: '14-20',
    ...SCORE_CELL,
  },
  {
    accessorKey: 'score',
    header: 'Score',
    ...SCORE_CELL,
  },
];

export const DRAFT_PREPARATION_LIST_COLS = [
  {
    accessorKey: 'user',
    header: 'User',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image',
    header: 'Opponent',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'side',
    header: 'Side',
    ...SIDE_CELL,
    size: 100,
  },
  {
    accessorKey: 'prio_picks',
    header: 'Prio Picks',
    ...IMAGE_LIST_DATA_CELL,
  },
  {
    accessorKey: 'worth_bans',
    header: 'Worth bans',
    ...IMAGE_LIST_DATA_CELL,
  },
  {
    accessorKey: 'first_picks',
    header: 'First picks',
    ...IMAGE_LIST_DATA_CELL,
  },
];

export const LIBRARY_GAMES_LIST_COLS = [
  {
    accessorKey: 'user',
    header: 'User',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image_1',
    header: 'Blue',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'image_2',
    header: 'Red',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 100,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 130,
  },
  {
    accessorKey: 'blue_picks',
    header: '',
    ...DRAFT_DATA_CELL,
    minSize: 225,
    maxSize: 225,
  },
  {
    accessorKey: 'red_picks',
    header: '',
    ...DRAFT_DATA_CELL,
    minSize: 225,
    maxSize: 225,
  },
  {
    accessorKey: 'url',
    header: 'VOD',
    ...VOD_CELL,
    size: 80,
  },
  {
    accessorKey: 'game_time',
    header: 'Time',
    ...STRING_TIME_TO_SECONDS_CELL,
    size: 100,
  },
  {
    accessorKey: 'image',
    header: 'Team',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    ...TAGS_CELL,
    size: 120,
    enableSorting: false,
  },
  {
    accessorKey: 'note',
    header: 'Note',
    size: 80,
    ...TOOLTIP_CELL,
  },
];

export const LIBRARY_SELECT_GAME_COLS = [
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'image_1',
    header: 'Blue',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'image_2',
    header: 'Red',
    ...IMAGE_CELL,
    size: 100,
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 100,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
    size: 130,
  },
  {
    accessorKey: 'blue_picks',
    header: '',
    ...DRAFT_DATA_CELL,
    minSize: 225,
    maxSize: 225,
  },
  {
    accessorKey: 'red_picks',
    header: '',
    ...DRAFT_DATA_CELL,
    minSize: 225,
    maxSize: 225,
  },
];

export const ADMIN_OPTIONS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'value',
    header: 'Value',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
    enableGlobalFilter: false,
    enableSorting: false,
  },
];

export const ADMIN_USERS_COLS = [
  {
    accessorKey: 'username',
    header: 'Username',
  },
  {
    accessorKey: 'logs',
    header: 'Logs',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'last_login',
    header: 'Last login',
    ...DATETIME_CELL,
  },
  {
    accessorKey: 'is_verified',
    header: 'Verified',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    size: 120,
    enableSorting: false,
  },
  {
    accessorKey: 'count_teams_access',
    header: 'Teams',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'count_players_home',
    header: 'Players',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const ADMIN_TAGS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
];

export const ADMIN_CHAMPIONS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'tags_string',
    header: 'Tags',
    size: 400,
  },
];

export const ADMIN_CHAMPION_SPELLS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'champion_name',
    header: 'Champion',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'key',
    header: 'Key',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 100,
    enableGlobalFilter: false,
  },
];

export const ADMIN_ITEMS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_PATCHES_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'patches',
    header: 'Patches',
    ...LIST_CELL,
  },
];

export const ADMIN_SUMMONER_SPELLS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'cooldown',
    header: 'Cooldown',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const ADMIN_RUNES_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_KEYSTONES_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'rune_name',
    header: 'Rune',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
];

export const ADMIN_PERKS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'rune_name',
    header: 'Rune',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
];

export const ADMIN_SHARDS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_REGIONS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_SERVERS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'region_name',
    header: 'Region',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'platform',
    header: 'Platform',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'ladder',
    header: 'Ladder',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
];

export const ADMIN_TIERS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'ladder',
    header: 'Ladder',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
];

export const ADMIN_ACCOUNTS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'tag',
    header: 'Tag',
    size: 100,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'server_name',
    header: 'Server',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'tier_name',
    header: 'Tier',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'hidden',
    header: 'Hidden',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'puu_id',
    header: 'PUU ID',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 850,
  },
];

export const ADMIN_RANKED_GAMES_COLS = [
  {
    accessorKey: 'game_id',
    header: 'Game ID',
  },
  {
    accessorKey: 'datetime',
    header: 'Date',
    ...DATETIME_CELL,
  },
  {
    accessorKey: 'server_name',
    header: 'Server',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 150,
  },
];

export const ADMIN_NATIONALITIES_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_LEAGUES_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ADMIN_SPLITS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'team_count',
    header: 'Teams',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'league_name',
    header: 'League',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'start_date',
    header: 'Start',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'end_date',
    header: 'End',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'leaguepedia',
    header: 'Leaguepedia',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 400,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'visible',
    header: 'Visible',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
];

export const ADMIN_TEAMS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'player_count',
    header: 'Players',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'sub_count',
    header: 'Subs',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'short',
    header: 'Short',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
  },
  {
    accessorKey: 'leaguepedia',
    header: 'Leaguepedia',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
    enableGlobalFilter: false,
  },
];

export const ADMIN_PLAYERS_COLS = [
  {
    accessorKey: 'image',
    header: '',
    ...IMAGE_CELL,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'account_count',
    header: 'Accounts',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'leaguepedia',
    header: 'Leaguepedia',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 400,
    enableGlobalFilter: false,
  },
];

export const ADMIN_PLAYER_URNS_COLS = [
  {
    accessorKey: 'urn',
    header: 'Urn',
  },
  {
    accessorKey: 'player_name',
    header: 'Player',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
  },
  {
    accessorKey: 'start_date',
    header: 'Start',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'end_date',
    header: 'End',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const ADMIN_PLAYER_STREAMS_COLS = [
  {
    accessorKey: 'url',
    header: 'URL',
  },
  {
    accessorKey: 'player_name',
    header: 'Player',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
  },
  {
    accessorKey: 'is_live',
    header: 'Live',
    ...IS_LIVE_CELL,
    enableSorting: false,
  },
];

export const ADMIN_MATCHES_COLS = [
  {
    accessorKey: 'date',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'games',
    header: 'Games',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
    size: 120,
  },
];

export const ADMIN_GAMES_COLS = [
  {
    accessorKey: 'datetime',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'patch',
    header: 'Patch',
    ...GAME_PATCH_CELL,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 150,
  },
  {
    accessorKey: 'blitz',
    header: 'Blitz',
    ...BOOLEAN_CELL,
    enableSorting: false,
  },
  {
    accessorKey: 'surrender',
    header: 'FF',
    ...BOOLEAN_CELL,
    size: 100,
    enableSorting: false,
  },
  {
    accessorKey: 'vod',
    header: 'VOD',
    ...VOD_CELL,
    size: 120,
  },
];

export const ADMIN_UNKNOWN_URNS_COLS = [
  {
    accessorKey: 'urn',
    header: 'Urn',
  },
  {
    accessorKey: 'name',
    header: 'Name',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 300,
  },
  {
    accessorKey: 'role',
    header: 'Role',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 120,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'start_date',
    header: 'Start',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const ADMIN_CHAMPIONS_QUEUE_REGIONS_COLS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'provider_id',
    header: 'Provider ID',
  },
];

export const ADMIN_CHAMPIONS_QUEUE_GAMES_COLS = [
  {
    accessorKey: 'datetime',
    header: 'Date',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    ...DURATION_CELL(),
  },
  {
    accessorKey: 'winner',
    header: 'Winner',
    ...SIDE_CELL,
    size: 150,
  },
];

export const ADMIN_GAME_ERRORS_COLS = [
  {
    accessorKey: 'provider_id',
    header: 'ID',
  },
  {
    accessorKey: 'error',
    header: 'Error',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 350,
  },
  {
    accessorKey: 'details',
    header: 'Details',
    /* eslint-disable */
    Cell: ({ cell }) => (
      <div dangerouslySetInnerHTML={{ __html: replaceWithBr(cell.getValue()) }}></div>
    ),
    /* eslint-enable */
    size: 500,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 150,
    enableGlobalFilter: false,
  },
];

export const ADMIN_CHAMPIONS_QUEUE_GAME_ERRORS_COLS = [
  {
    accessorKey: 'provider_id',
    header: 'ID',
  },
  {
    accessorKey: 'error',
    header: 'Error',
    ...SET_CENTER_DATA_IN_COLUMN,
    size: 350,
  },
  {
    accessorKey: 'details',
    header: 'Details',
    /* eslint-disable */
    Cell: ({ cell }) => (
      <div dangerouslySetInnerHTML={{ __html: replaceWithBr(cell.getValue()) }}></div>
    ),
    /* eslint-enable */
    size: 500,
  },
];
