// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchPreparations = async (user, matchType, side, teams, startDate, endDate) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}preparations/`);

  if (user !== undefined && user !== null) {
    url.searchParams.append('user', user);
  }

  if (matchType !== undefined && matchType !== null) {
    url.searchParams.append('item_type', matchType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teams !== undefined && teams !== null) {
    url.searchParams.append('teams', teams.map((team) => team.value).join(','));
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSinglePreparation = (id) =>
  axiosLoadingInstance
    .get(`preparations/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addPreparation = (data) =>
  axiosInstance
    .post(`preparations/add/`, data)
    .then((res) => ({ data: res.data, message: 'Preparation has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updatePreparation = (id, data) =>
  axiosInstance
    .put(`preparations/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Preparation has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deletePreparation = (id) =>
  axiosInstance
    .delete(`preparations/${id}/`)
    .then((res) => ({ data: res.data, message: 'Preparation has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
