// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSingleChampionsQueueBySlug } from 'services/championsQueues';
import { fetchChampionsQueueChampionPool } from 'services/championsQueueData';

// Constants
import { HIDE_PAGINATION } from 'constants/mrt';
import { CHAMPIONS_QUEUE_CHAMPION_DRAFT_STATS_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const ChampionsQueueSingleChampionPoolView = () => {
  const {
    setTitleSEO,
    filters: {
      championsQueue: { id, rangeDate, roleAll, side },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { regionSlug } = useParams();
  const [championsQueue, setChampionsQueue] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (regionValue) => {
    setIsLoading(true);

    fetchSingleChampionsQueueBySlug(regionValue).then((res) => setChampionsQueue(res));
  };

  const fetchData = (sideData, roleData, regionId, startDateData, endDateData) => {
    setIsLoading(true);

    fetchChampionsQueueChampionPool(sideData, roleData, regionId, startDateData, endDateData).then(
      (res) => {
        setData(res);

        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(regionSlug);
    }

    renderAfterCalled.current = true;
  }, [regionSlug]);

  useEffect(() => {
    if (championsQueue) {
      fetchData(side, roleAll, championsQueue.id, rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate, side, roleAll, championsQueue]);

  useEffect(() => {
    if (championsQueue) {
      const tempTitle = `Champions Queue ${championsQueue.name} Champion Pool`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);

      if (id !== championsQueue.id) {
        setFilters((prevValue) => ({
          ...prevValue,
          championsQueue: {
            ...prevValue.championsQueue,
            id: championsQueue.id,
          },
        }));
      }
    }
  }, [championsQueue]);

  const columns = useMemo(() => CHAMPIONS_QUEUE_CHAMPION_DRAFT_STATS_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter sideFilter roleAllFilter type="championsQueue" />
          <MaterialReactTable table={table} />
        </>
      }
    />
  );
};

export default ChampionsQueueSingleChampionPoolView;
