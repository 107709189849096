// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import RosterCard from 'components/molecules/RosterCard/RosterCard';
import ChampionsCard from 'components/molecules/ChampionsCard/ChampionsCard';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import CardValue from 'components/atoms/CardValue/CardValue';
import WinRateText from 'components/molecules/WinRateText/WinRateText';
import CardPie from 'components/templates/CardPie/CardPie';
import TeamStatisticCard from 'components/molecules/TeamStatisticCard/TeamStatisticCard';

// Component
const OverviewData = ({
  data = null,
  side = null,
  teamData = false,
  splitData = false,
  championsQueueData = false,
}) => {
  const winRateSideNotExists =
    data?.sides && Object.keys(data.sides).length > 0 && !('win_rate_side' in data);
  const showSideData = side !== 'ALL';

  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  const dataStatisticsOverall = [
    {
      name: 'Kills',
      value: data?.statistics?.kills.toFixed(2),
    },
    {
      name: 'Deaths',
      value: data?.statistics?.deaths.toFixed(2),
      mt: 0.25,
    },
    {
      name: 'Assists',
      value: data?.statistics?.assists.toFixed(2),
      mt: 0.25,
    },
    {
      name: 'Plates',
      value: data?.statistics?.plates.toFixed(2),
      mt: 2,
    },
    {
      condition: showSideData,
      key: 'plates_diff',
      name: 'Diff',
      value: data?.statistics?.plates_diff,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'Grubs',
      value: data?.statistics?.grubs.toFixed(2),
      mt: 2,
    },
    {
      condition: showSideData,
      key: 'grubs_diff',
      name: 'Diff',
      value: data?.statistics?.grubs_diff,
      type: 'diff',
      mt: 0.25,
    },
  ];

  const dataStatisticsFirst = [
    {
      condition: showSideData,
      name: 'FB',
      value: data?.first?.kill,
      type: 'percentage',
    },
    {
      condition: showSideData,
      name: 'FH',
      value: data?.first?.herald,
      type: 'percentage',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'FD',
      value: data?.first?.dragon,
      type: 'percentage',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'FT',
      value: data?.first?.tower,
      type: 'percentage',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'Lead',
      value: `${data?.game_state?.lead.toFixed(2)}%`,
      mt: 2,
    },
    {
      condition: showSideData,
      name: 'Deficit',
      value: `${data?.game_state?.deficit.toFixed(2)}%`,
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'Close',
      value: `${data?.game_state?.close.toFixed(2)}%`,
      mt: 0.25,
    },
  ];

  const dataStatisticsEarly = [
    {
      condition: showSideData,
      name: 'K+A@14',
      value: data?.early?.ka.toFixed(2),
    },
    {
      condition: showSideData,
      name: 'GD@14',
      value: data?.early?.gd,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'XPD@14',
      value: data?.early?.xpd,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'CSD@14',
      value: data?.early?.csd,
      type: 'diff',
      mt: 0.25,
    },
  ];

  const dataStatisticsVision = [
    {
      condition: showSideData,
      name: 'WPM',
      value: data?.vision?.wppm.toFixed(2),
    },
    {
      condition: showSideData,
      name: 'WKPM',
      value: data?.vision?.wkpm.toFixed(2),
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'Cleared',
      value: data?.vision?.cleared,
      type: 'percentage',
      mt: 2,
    },
  ];

  const dataStatisticsTowers = [
    {
      name: 'Count',
      value: data?.towers?.count,
    },
    {
      condition: showSideData,
      name: 'Diff',
      value: data?.towers?.diff,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'TD@20',
      value: data?.towers?.td_20,
      type: 'diff',
      mt: 2,
    },
  ];

  const dataStatisticsHeralds = [
    {
      condition: showSideData,
      name: 'Count',
      value: data?.heralds?.count,
    },
    {
      condition: showSideData,
      name: 'Diff',
      value: data?.heralds?.diff,
      type: 'diff',
      mt: 0.25,
    },
  ];

  const dataStatisticsDragons = [
    {
      name: 'Count',
      value: data?.dragons?.count,
    },
    {
      condition: showSideData,
      name: 'Diff',
      value: data?.dragons?.diff,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'DD@20',
      value: data?.dragons?.dd_20,
      type: 'diff',
      mt: 2,
    },
  ];

  const dataStatisticsAtakhans = [
    {
      name: 'Count',
      value: data?.atakhans?.count,
    },
    {
      name: 'TOP',
      value: data?.atakhans?.top,
      type: 'percentage',
      mt: 2,
    },
    {
      name: 'BOT',
      value: data?.atakhans?.bot,
      type: 'percentage',
      mt: 0.25,
    },
    {
      name: 'HIGH',
      value: data?.atakhans?.high,
      type: 'percentage',
      mt: 2,
    },
    {
      name: 'LOW',
      value: data?.atakhans?.low,
      type: 'percentage',
      mt: 0.25,
    },
  ];

  const dataStatisticsBarons = [
    {
      name: 'Count',
      value: data?.barons?.count,
    },
    {
      condition: showSideData,
      name: 'Diff',
      value: data?.barons?.diff,
      type: 'diff',
      mt: 0.25,
    },
    {
      condition: showSideData,
      name: 'BPP',
      value: data?.barons?.bpp,
      type: 'diff',
      mt: 2,
    },
  ];

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(3, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: 'repeat(6, 1fr)',
        xl: 'repeat(8, 1fr)',
      }}
      gap={1}
    >
      {teamData ? <RosterCard data={data?.roster} /> : null}
      {data?.picks?.map(({ role, champions }) => (
        <ChampionsCard key={role} title={role} data={champions} />
      ))}
      {splitData ? (
        <>
          <ChampionsCard title="Bans" data={data?.bans?.all} bans />
          <ChampionsCard title="Bans 1st phase" data={data?.bans['1']} bans />
          <ChampionsCard title="Bans 2nd phase" data={data?.bans['2']} bans />
        </>
      ) : null}
      {teamData ? (
        <>
          <ChampionsCard title="Bans" data={data?.bans?.team} bans />
          <ChampionsCard title="Enemy bans" data={data?.bans?.enemy} bans />
        </>
      ) : null}
      {championsQueueData ? (
        <>
          <Box />
          <Box />
          <Box />
        </>
      ) : null}
      <Card>
        <CardContent>
          {winRateSideNotExists && !splitData && !championsQueueData ? (
            <>
              <CardTitle text="Win rate" />
              <WinRateText data={data?.win_rate} />
            </>
          ) : null}
          {splitData || championsQueueData ? (
            <>
              <CardTitle text="Games" />
              <CardValue value={data?.games} center />
            </>
          ) : null}
          <CardTitle
            text="Game time"
            mt={winRateSideNotExists || splitData || championsQueueData ? 2 : 0}
          />
          <CardValue value={data?.time?.value} center />
          {(!splitData && !championsQueueData) || side !== 'ALL' ? (
            <>
              <CardTitle text="Win / loss time" mt={2} />
              <CardValue value={`${data?.time?.wins} / ${data?.time?.losses}`} center />
            </>
          ) : null}
        </CardContent>
      </Card>
      {winRateSideNotExists && !splitData && !championsQueueData ? (
        <CardPie title="Sides" data={data?.sides} type="side" />
      ) : null}
      {!winRateSideNotExists && !splitData && !championsQueueData ? (
        <CardPie title="Win rate" data={data?.win_rate_side} type="win_rate" />
      ) : null}
      {splitData || championsQueueData ? (
        <CardPie title="Win rate" data={data?.win_rate} type="win_rate" chartKey="value" />
      ) : null}
      <TeamStatisticCard data={dataStatisticsOverall} />
      <TeamStatisticCard data={dataStatisticsFirst} />
      <CardPie title="DMG%" data={data?.share?.dmg} type="roles" />
      <CardPie title="GOLD%" data={data?.share?.gold} type="roles" />
      <CardPie title="Plates" data={data?.share?.plates} type="lanes" />
      <CardPie title="Control wards" data={data?.share?.control_wards} type="roles" />
      <TeamStatisticCard title="Early game" data={dataStatisticsEarly} />
      <TeamStatisticCard title="Vision" data={dataStatisticsVision} />
      <TeamStatisticCard title="Towers" data={dataStatisticsTowers} />
      <TeamStatisticCard title="Heralds" data={dataStatisticsHeralds} />
      <TeamStatisticCard title="Dragons" data={dataStatisticsDragons} />
      <TeamStatisticCard title="Atakhan" data={dataStatisticsAtakhans} />
      <TeamStatisticCard title="Barons" data={dataStatisticsBarons} />
    </Box>
  );
};

OverviewData.propTypes = {
  data: PropTypes.object,
  side: PropTypes.string,
  teamData: PropTypes.bool,
  splitData: PropTypes.bool,
  championsQueueData: PropTypes.bool,
};

export default OverviewData;
