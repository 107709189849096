// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleChampionBySlug, fetchChampionOverview } from 'services/champions';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import ChampionData from 'components/organisms/ChampionData/ChampionData';

const ChampionSingleView = () => {
  const {
    setTitleSEO,
    filters: {
      champion: { matchTypeS, rangeDate, side, patches, patches_data },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { championSlug } = useParams();
  const [champion, setChampion] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (championValue) => {
    setIsLoading(true);

    fetchSingleChampionBySlug(championValue).then((res) => setChampion(res));
  };

  const fetchData = (championId, itemType, startDate, endDate, sideData, patchesData) => {
    setIsLoading(true);

    fetchChampionOverview(championId, itemType, startDate, endDate, sideData, patchesData).then(
      (res) => {
        setData(res.data);

        setFilters((prevValue) => ({
          ...prevValue,
          champion: { ...prevValue.champion, patches_data: res.patches_data },
        }));

        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(championSlug);
    }

    renderAfterCalled.current = true;
  }, [championSlug]);

  useEffect(() => {
    if (champion) {
      fetchData(champion.id, matchTypeS, rangeDate[0], rangeDate[1], side, patches);
    }
  }, [champion, matchTypeS, rangeDate, side, patches]);

  useEffect(() => {
    if (champion) {
      const tempTitle = `${champion.name} Overview`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [champion]);

  return (
    <ViewWithSidebar
      component={
        <>
          {champion && (
            <ImageTitle image={champion?.image_url} name={champion?.name} title={title} />
          )}
          <DataFilters
            matchTypeSFilter
            rangeDateFilter
            sideFilter
            patchesFilter={patches_data}
            type="champion"
          />
          <ChampionData data={data} />
        </>
      }
    />
  );
};

export default ChampionSingleView;
