// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSinglePlayerBySlug } from 'services/players';
import { fetchPlayerStatistics } from 'services/data';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';
import { PLAYER_STATISTICS_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Grid2 as Grid } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PlayerInfo from 'components/organisms/PlayerInfo/PlayerInfo';

const PlayerSingleStatisticsView = () => {
  const {
    setTitleSEO,
    filters: {
      player: {
        matchTypeS,
        rangeDate,
        side,
        playerMainRole,
        patches,
        patches_data,
        opponents,
        opponents_data,
        timerange,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { playerSlug } = useParams();
  const [player, setPlayer] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);

  const fetchInfo = (playerValue) => {
    setIsLoading(true);

    fetchSinglePlayerBySlug(playerValue).then((res) => setPlayer(res));
  };

  const fetchData = (
    playerId,
    itemType,
    sideData,
    timerangeData,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
    playerMainRoleData,
  ) => {
    setIsLoading(true);

    fetchPlayerStatistics(
      playerId,
      itemType,
      sideData,
      timerangeData,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
      playerMainRoleData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        player: {
          ...prevValue.player,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(playerSlug);
    }

    renderAfterCalled.current = true;
  }, [playerSlug]);

  useEffect(() => {
    if (player) {
      fetchData(
        player.id,
        matchTypeS,
        side,
        timerange,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
        playerMainRole,
      );
    }
  }, [player, matchTypeS, rangeDate, side, timerange, patches, opponents, blitz, playerMainRole]);

  useEffect(() => {
    if (player) {
      setTitle(`${player.name}`);
    }
  }, [player]);

  useEffect(() => {
    setTitleSEO(`${title} Statistics`);
  }, [title]);

  const columns = useMemo(() => PLAYER_STATISTICS_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        player ? (
          <>
            <DataFilters
              matchTypeSFilter
              rangeDateFilter
              sideFilter
              patchesFilter={patches_data}
              opponentsFilter={opponents_data}
              timerangeFilter
              blitzFilter
              playerMainRoleFilter
              type="player"
            />
            <Grid container>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
                <PlayerInfo player={player} title={title} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 8, lg: 9, xl: 10 }}>
                <MaterialReactTable table={table} />
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )
      }
    />
  );
};

export default PlayerSingleStatisticsView;
