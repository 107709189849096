// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchWardsTimeline } from 'services/data';

// Constants
import { WARDS_PER_ROLE } from 'constants/ward';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid2 as Grid } from '@mui/material';
import GameMap from 'components/molecules/GameMap/GameMap';
import WardsListPerRole from 'components/molecules/WardsListPerRole/WardsListPerRole';

const TeamSingleWardsTimelineView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        roleAll,
        wardType,
        timerange,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [wardsPerRole, setWardsPerRole] = useState({});
  const [currentWard, setCurrentWard] = useState(null);
  const [count, setCount] = useState(0);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    roleData,
    wardTypeData,
    timerangeData,
    teamId,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchWardsTimeline(
      itemType,
      sideData,
      roleData,
      wardTypeData,
      timerangeData,
      teamId,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      const wardsData = res.data;
      const wardsPerRoleData = JSON.parse(JSON.stringify(WARDS_PER_ROLE));

      wardsData.forEach((ward) => {
        const { role } = ward;

        wardsPerRoleData[role].push(ward);
      });

      Object.keys(wardsPerRoleData).forEach((role) => {
        wardsPerRoleData[role].sort((a, b) => a.time - b.time);
      });

      setData(wardsData);
      setWardsPerRole(wardsPerRoleData);
      setCount(res.count);

      setFilters((prevValue) => ({
        ...prevValue,
        team: {
          ...prevValue.team,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        roleAll,
        wardType,
        timerange,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, roleAll, wardType, timerange, team, patches, opponents, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Wards Timeline`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            roleAllFilter
            wardTypeFilter
            timerangeFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="team"
          />
          <Grid container>
            <Grid size={{ xs: 12, md: 7.5, lg: 5.5, xl: 4.5 }}>
              <GameMap wards={data} count={count} currentWard={currentWard} />
            </Grid>
            <Grid size={{ xs: 12, md: 4.5, lg: 6.5, xl: 7.5 }}>
              <WardsListPerRole data={wardsPerRole} setCurrentWard={setCurrentWard} />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleWardsTimelineView;
