// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchDrafts } from 'services/data';

// Constants
import { MATCH_TYPES } from 'constants/match';

// Elements
import DraftsTemplate from 'components/templates/DraftsTemplate/DraftsTemplate';

const ScrimsDraftsView = () => {
  const {
    setTitleSEO,
    filters: {
      scrims: {
        rangeDate,
        side,
        result,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
        draftFilter,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Scrims Drafts';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const fetchData = (
    itemType,
    sideData,
    resultData,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
    draftFilterData,
  ) => {
    setIsLoading(true);

    fetchDrafts(
      itemType,
      sideData,
      resultData,
      null,
      startDateData,
      endDateData,
      patchesData,
      opponentsData,
      blitzData,
      draftFilterData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        scrims: {
          ...prevValue.scrims,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(
        MATCH_TYPES.SCRIM,
        side,
        result,
        rangeDate[0],
        rangeDate[1],
        patches,
        opponents,
        blitz,
        draftFilter,
      );
    }
  }, [rangeDate, side, result, patches, opponents, blitz, draftFilter]);

  return (
    <DraftsTemplate
      data={data}
      title={title}
      patches_data={patches_data}
      opponents_data={opponents_data}
    />
  );
};

export default ScrimsDraftsView;
