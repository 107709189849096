// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import GameDurationData from 'components/atoms/GameDurationData/GameDurationData';
import GameThresholdData from 'components/atoms/GameThresholdData/GameThresholdData';

// Component
const ColumnTableData = ({
  type = 'simple',
  data = null,
  avgData = null,
  medianData = null,
  maxData = null,
  minData = null,
  postFix = '',
  threshold = null,
  isBoolean = false,
  alignLeft = false,
}) => {
  const renderData = (dataValue, label) => {
    if (dataValue !== null && !Number.isNaN(dataValue)) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems={!alignLeft && 'center'}
          textAlign={!alignLeft && 'center'}
          mt={0.5}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{ fontSize: 12, fontWeight: 'bold', textAlign: !alignLeft && 'center' }}
          >
            {label}:
          </Typography>
          {type === 'diff' ? <GameDiffData value={dataValue} postFix={postFix} /> : null}
          {type === 'percentage' ? (
            <GamePercentageData value={dataValue} threshold={threshold} />
          ) : null}
          {type === 'duration' ? (
            <GameDurationData value={dataValue} threshold={threshold} />
          ) : null}
          {type === 'durationDiff' ? <GameDurationData value={dataValue} diff /> : null}
          {type === 'threshold' ? (
            <GameThresholdData value={dataValue} threshold={threshold} />
          ) : null}
          {type === 'threshold_inverse' ? (
            <GameThresholdData value={dataValue} threshold={threshold} inverse />
          ) : null}
          {type === 'simple' ? (
            <Typography variant="body2" component="p" sx={{ textAlign: !alignLeft && 'center' }}>
              {dataValue}
            </Typography>
          ) : null}
        </Box>
      );
    }

    return null;
  };

  return (
    <Box>
      {data !== null ? (
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          {Object.keys(data).map((key) => (
            <Typography key={key} variant="body2" component="p">
              {key}: {data[key]}
            </Typography>
          ))}
        </Box>
      ) : null}
      {renderData(avgData, 'AVG')}
      {renderData(medianData, 'MEDIAN')}
      {!isBoolean ? renderData(maxData, 'MAX') : null}
      {!isBoolean ? renderData(minData, 'MIN') : null}
    </Box>
  );
};

ColumnTableData.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  avgData: PropTypes.number,
  medianData: PropTypes.number,
  maxData: PropTypes.number,
  minData: PropTypes.number,
  postFix: PropTypes.string,
  threshold: PropTypes.number,
  isBoolean: PropTypes.bool,
  alignLeft: PropTypes.bool,
};

export default ColumnTableData;
